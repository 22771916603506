import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDropDown } from '@material-ui/icons';
import { ButtonSelect } from 'src/components';
import { useSelector } from 'react-redux';
import { useMenu } from 'src/hooks';
import AdvFilter from './AdvFilter';

function generateChoiceFromWF(wf = {}) {
  const res = [];

  for (const key of Object.keys(wf)) {
    res.push({ value: wf[key].ixStatus, label: wf[key].sStatus });
  }

  return res;
}

function generateFiltersFromData(field = '', data = []) {
  const res = [];

  for (const item of data) {
    if (res.every(resItem => resItem.value !== item[field])) {
      res.push({ value: item[field], label: item[field] });
    }
  }

  return res;
}

function generateChoices(col, wf, render, data) {
  if (render?.type === 'wf')
    return generateChoiceFromWF(wf?.[render.ixJCd]?.wf || {});

  if (!col.filter.hasOwnProperty('items'))
    return generateFiltersFromData(col.field, data);

  return col.filter?.items || [];
}

/**
 *
 * @param {object} param
 * @param {boolean} param.showAdvancedFilter
 * @param {object} param.advancedFilter
 * @param {column[]} param.filters
 * @param {{}} param.filterValues
 * @param {(name: string, value: number | string) => void} param.updateFilters
 * @param {() => void} param.submitAdvFilter
 * @param {object[]} param.data
 * @returns
 */
const TblFilter = ({
  showAdvancedFilter = false,
  advancedFilter = {},
  columns = [],
  filters = [],
  filterValues,
  updateFilters,
  submitAdvFilter,
  data = []
}) => {
  const theme = useTheme();
  const [anchor, open, close] = useMenu();

  const { wf = {} } = useSelector(state => state.bizMeta);

  function renderFilter(col) {
    return (
      <Box
        key={col.field}
        display="flex"
        justifyContent="center"
        gridGap={4}
        flexDirection="column"
      >
        <Typography variant="caption" color="textSecondary">
          {col.headerName}
        </Typography>
        <ButtonSelect
          rounded
          size="small"
          value={filterValues?.[col.field] || 'all'}
          choices={[
            { value: 'all', label: 'All' },
            ...generateChoices(col, wf, col.render, data)
          ]}
          onChange={val => updateFilters(col.field, val)}
          style={{ textTransform: 'none' }}
        />
      </Box>
    );
  }

  function getAdvancedFilterLabel() {
    const filteredColumns = columns.filter(column =>
      advancedFilter.fields.includes(column.field)
    );

    const columnHeaders = filteredColumns.map(column => column.headerName);

    if (columnHeaders.length === 0) return '';

    const formattedColumns =
      columnHeaders.length > 1 ? columnHeaders.join(' or ') : columnHeaders[0];

    return `Showing results where ${formattedColumns} ${advancedFilter.operator} "${advancedFilter.value}"`;
  }

  return (
    <Box px={2} py={1} display="flex" gridGap={theme.spacing(2)}>
      <FontAwesomeIcon
        icon={faFilter}
        color={theme.palette.text.secondary}
        style={{ marginRight: theme.spacing(1) }}
      />

      {showAdvancedFilter && (
        <Box
          display="flex"
          justifyContent="center"
          gridGap={4}
          flexDirection="column"
        >
          <Typography variant="caption" color="textSecondary">
            Advanced Filter
          </Typography>
          <Button
            onClick={e => open(e)}
            size="small"
            color="primary"
            variant="contained"
            endIcon={<ArrowDropDown />}
            style={{ borderRadius: 50, textTransform: 'none' }}
          >
            Adv. Filter : {getAdvancedFilterLabel()}
          </Button>
        </Box>
      )}

      <AdvFilter
        anchor={anchor}
        onClose={close}
        columns={columns.filter(item => item.field !== 'actions')}
        onSubmit={submitAdvFilter}
        operator={advancedFilter.operator}
        fields={advancedFilter.fields}
        value={advancedFilter.value}
      />

      {filters.map(item => renderFilter(item))}
    </Box>
  );
};

export default React.memo(TblFilter);
