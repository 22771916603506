import {
  Box,
  Button,
  Typography,
  Popover,
  TextField,
  MenuItem
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SelectWithSearchV2 from 'src/components/SelectWithSearchV2';

const Menu = ({ value, description, ...props }) => (
  <MenuItem {...props}>{description}</MenuItem>
);

const AdvFilter = ({
  anchor,
  onClose,
  onSubmit,
  columns = [],
  operator,
  fields,
  value
}) => {
  const [currentOperator, setOperator] = useState('');
  const [currentFields, setFields] = useState([]);
  const [currentValue, setValue] = useState('');

  function handleSubmit() {
    onSubmit({
      operator: currentOperator,
      fields: currentFields,
      value: currentValue
    });
    onClose();
  }

  function handleClear() {
    onSubmit({
      operator: '',
      fields: '',
      value: ''
    });
    onClose();
  }

  function handleSelectFields(e) {
    const value = e.target.value;
    setFields(value);
  }

  useEffect(() => {
    setOperator(operator);
    setFields(fields);
    setValue(value);
  }, []);

  return (
    <Popover
      open={Boolean(anchor)}
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box
        py={2}
        px={2}
        width={340}
        display="flex"
        flexDirection="column"
        gridGap={8}
      >
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Advanced Filter
        </Typography>

        <SelectWithSearchV2
          value={currentFields}
          options={columns.map(col => ({
            value: col.field,
            description: col.headerName
          }))}
          label="Columns"
          searchKeys={['description']}
          onChange={handleSelectFields}
          searchPlaceHolder="Search..."
          CustomMenuItem={Menu}
          selectAllUsers="all"
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Operator"
          value={currentOperator}
          onChange={e => setOperator(e.target.value)}
          select
        >
          <MenuItem value={'Equals'}>Equals To</MenuItem>
          <MenuItem value={'Not Equals'}>Not Equals To</MenuItem>
          <MenuItem value={'Like'}>Like</MenuItem>
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          label="Value"
          value={currentValue}
          onChange={e => setValue(e.target.value)}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap={8}
        >
          <Button color="primary" size="small" onClick={handleClear}>
            Clear
          </Button>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gridGap={8}
          >
            <Button color="primary" size="small" onClick={onClose}>
              Cancel
            </Button>

            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default React.memo(AdvFilter);
