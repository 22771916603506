import { useSelector } from 'react-redux';
import { JNT_ERRORS } from 'src/constants';
import useRequest from '../useRequest';
import { jnt } from 'src/lib/endpoints';
import moment from 'moment';
import { round } from 'lodash';

const BEST_PICK_UP_DEFAULT_START_TIME = 8;
const BEST_PICK_UP_DEFAULT_END_TIME = 20;

/**
 * Maps JNT API error code to a more descriptive error message
 * @param {string} [code=''] - JNT API error code
 * @returns {Object} - Object containing the error description and explanation
 * @property {string} desc - Error description
 * @property {string} explain - Detailed explanation of the error
 */
export function mapError(code = '') {
  const splitted_code = code.split(',');

  if (splitted_code.length > 1) {
    return {
      desc: 'Error',
      explanation: splitted_code
        .map(
          cd =>
            `${cd} : ${JNT_ERRORS?.[cd]?.explanation ??
              'Something went wrong'}.`
        )
        .join('\n')
    };
  }

  return {
    desc: JNT_ERRORS?.[code]?.desc ?? 'Error',
    explanation: `${code} : ${JNT_ERRORS?.[code]?.explanation ??
      'Something went wrong'}`
  };
}

function useJNTApi() {
  const { frontEnd = {} } = useSelector(({ bizMeta }) => bizMeta);
  const {
    customerid = '',
    eccompanyid = '',
    sender = {},
    ordertype = '1',
    servicetype = '6',
    deliverytype = '1',
    paytype = '1'
  } = frontEnd?.api?.courier?.providers.jnt ?? {};
  const req = useRequest();

  /**
   * @function add
   * @description Create a new order
   * @param {Object} order_details - The details of the order to be created
   * @param {string} order_details.txlogisticid - The ID of the logistics order
   * @param {Object} order_details.receiver - The receiver's information
   * @param {string} order_details.receiver.name - The receiver's name
   * @param {string} order_details.receiver.postcode - The receiver's postcode
   * @param {string} order_details.receiver.mobile - The receiver's mobile phone number
   * @param {string} order_details.receiver.prov - The receiver's province
   * @param {string} order_details.receiver.city - The receiver's city
   * @param {string} order_details.receiver.area - The receiver's area
   * @param {string} order_details.receiver.address - The receiver's address
   * @param {string} order_details.weight - The weight of the order
   * @param {string} order_details.itemsvalue - The total value of the items
   * @param {string} order_details.totalquantity - The total quantity of the items
   * @param {string} order_details.remark - The remark of the order
   * @param {Array} order_details.items - The items in the order
   * @param {Object} order_details.items[] - The item information
   * @param {string} order_details.items[].itemname - The name of the item
   * @param {string} order_details.items[].number - The quantity of the item
   * @param {string} order_details.items[].itemvalue - The value of the item
   * @param {string} order_details.items[].desc - The description of the item
   * @return {Object} The response of the API
   */
  async function createOrder(order_details = {}) {
    const now = moment();
    const DATA = {
      actiontype: 'add',
      environment: 'production',
      eccompanyid,
      customerid,
      ordertype,
      servicetype,
      deliverytype,
      sender,
      createordertime: now.format('YYYY-MM-DD HH:mm:ss'),
      sendstarttime: now
        .startOf('day')
        .hour(BEST_PICK_UP_DEFAULT_START_TIME)
        .format('YYYY-MM-DD HH:mm:ss'),
      sendendtime: now
        .startOf('day')
        .hour(BEST_PICK_UP_DEFAULT_END_TIME)
        .format('YYYY-MM-DD HH:mm:ss'),
      paytype,
      ...order_details
    };

    if (DATA.items.length === 0) {
      return {
        success: false,
        explanation: 'Inventory items is empty'
      };
    }

    if (!Boolean(DATA?.weight) || +DATA?.weight === 0 || +DATA?.weight === '') {
      return {
        success: false,
        explanation: 'Weight is required.'
      };
    }

    const { success, data } = await req.post(jnt.addOrder, {
      msg_type: 'ORDERCREATE',
      eccompanyid,
      logistics_interface: DATA
    });
    const response = data?.responseitems?.[0] ?? {
      success: false,
      reason: ''
    };

    return success && response.success === 'true'
      ? response
      : { success: false, ...mapError(response?.reason) };
  }

  /**
   * Asynchronously tracks the given bill codes
   * @param {string} [bill_codes=''] - Bill codes to be tracked
   * @returns {Object} - The response object containing the success status and reason for failure, if any
   */
  async function trackOrder(bill_codes = '') {
    if (bill_codes.length > 2000) {
      return {
        success: false,
        desc: 'Too many bill codes.',
        explanation: 'Too many bill codes.'
      };
    }

    const { success, data } = await req.post(jnt.trackOrder, {
      logistics_interface: {
        billcode: bill_codes,
        lang: 'en'
      },
      eccompanyid,
      msg_type: 'TRACKQUERY'
    });

    const response = data?.responseitems?.[0] ?? {
      success: false,
      reason: ''
    };

    return success && response.success === 'true'
      ? response
      : { success: false, ...mapError(response?.reason ?? '') };
  }

  async function trackOrders(bill_codes = []) {
    const bill_code_str = bill_codes.join(',');

    if (bill_code_str.length > 2000) {
      return {
        success: false,
        items: bill_codes.map(billcode => ({
          billcode,
          desc: 'Too many bill codes.',
          explanation: 'Too many bill codes.',
          details: []
        }))
      };
    }

    const { success, data, errorMessage } = await req.post(jnt.trackOrder, {
      logistics_interface: {
        billcode: bill_code_str,
        lang: 'en'
      },
      eccompanyid,
      msg_type: 'TRACKQUERY'
    });

    if (!success) {
      return bill_codes.map(billcode => ({
        success: false,
        billcode,
        desc: errorMessage || 'Something went wrong',
        explanation: errorMessage || 'Something went wrong',
        details: []
      }));
    }

    return (data?.responseitems ?? []).map(data => ({
      ...data,
      desc: data.success === 'true' ? '' : 'Waybill No not found.',
      explanation: data.success === 'true' ? '' : 'Waybill No not found.'
    }));
  }

  function formatItem(props, format) {
    const variables = [
      'ProdCd',
      'sProd',
      'BN',
      'SN',
      'SN2',
      'Remarks',
      'qty',
      'price',
      'amt',
      'netAmt',
      'unit'
    ];
    let result = format;

    for (const variable of variables)
      result = result.replaceAll(`<<${variable}>>`, props[variable]);

    return result;
  }

  async function getOrderDetails(
    jv = {},
    config = {
      customer: '',
      weight: '',
      sender: '',
      sender_mode: 0,
      sender_name_source: '',
      sender_name_prop: 'sSub',
      compress_items: false,
      item_name_if_compressed: '',
      item_name_if_compressed_source: '',
      item_name_if_compressed_key: '',
      remark: 'Particulars',
      product_as_remark: true,
      product_as_remark_delimeter: '+',
      product_as_remark_format: '<<qty>>;<ProdCd>',
      payment_method: '',
      non_cod_values: [],
      best_pick_up_time_days_offset: 0,
      best_pick_up_start_time: 0,
      best_pick_up_end_time: 0
    }
  ) {
    const details = {
      txlogisticid: '',
      receiver: {
        name: '',
        postcode: '',
        mailbox: '',
        mobile: '',
        prov: '',
        city: '',
        area: '',
        address: ''
      },
      weight: '',
      itemsvalue: '',
      totalquantity: '',
      remark: '',
      items: []
    };

    let senderDetails = {};

    const { success, data: customer_details } = await req.get(
      '/lib/sub/' + jv[config.customer]
    );

    if (!success) return details;

    details.receiver.name = customer_details.sSub;
    details.receiver.postcode = customer_details.addressZIP;
    details.receiver.mailbox = '';
    details.receiver.mobile = customer_details.contactNo;
    details.receiver.prov = customer_details.addressProvince;
    details.receiver.city = customer_details.addressCity;
    details.receiver.area = customer_details.addressBrgy;
    details.receiver.address = `${customer_details.addressStreet} ${customer_details.addressBrgy} ${customer_details.addressCity} ${customer_details.addressProvince}`;

    // sender details
    if (config.sender_mode === 1 && config.sender !== '') {
      const res = await req.get('/lib/sub/' + jv[config.sender]);

      if (!res.success) return details;

      details.sender = {};

      const defaultSenderName = res.data.sSub;
      if (config.sender_name_source === 'kvs') {
        details.sender.name =
          res.data.kvs.find(item => item.key === config.sender_name_prop)
            ?.value ?? defaultSenderName;
      } else {
        details.sender.name =
          res.data?.[config.sender_name_prop] ?? defaultSenderName;
      }

      senderDetails = res?.data ?? {};
      details.sender.postcode = res.data.addressZIP;
      details.sender.mailbox = '';
      details.sender.mobile = res.data.contactNo;
      details.sender.prov = res.data.addressProvince;
      details.sender.city = res.data.addressCity;
      details.sender.area = res.data.addressBrgy;
      details.sender.address = `${res.data.addressStreet} ${res.data.addressBrgy} ${res.data.addressCity} ${res.data.addressProvince}`;
    }

    const items = (jv?.trans_inv?.items ?? []).map(item => ({
      itemname: item.sProd,
      number: item.qty,
      itemvalue: round(item.netAmt, 4),
      desc: item.sProd
    }));

    const paymentType =
      jv?.kvs.find(item => item.key === config.payment_method)?.value ?? '';

    if (config.non_cod_values.includes(paymentType)) details.itemsvalue = '0';
    else {
      details.itemsvalue = `${items.reduce(
        (acc, prev) => acc + prev.itemvalue,
        0
      )}`;
    }

    details.totalquantity = `${items.reduce(
      (acc, prev) => acc + prev.number,
      0
    )}`;

    if (config?.compress_items) {
      details.items = items
        .reduce((acc, prev) => {
          if (acc.length === 0) acc.push(prev);
          else {
            acc[0].number += prev.number;
            acc[0].itemvalue += prev.itemvalue;
          }
          return acc;
        }, [])
        .map(item => {
          return Object.keys(item).reduce((acc, prev) => {
            acc = {
              ...acc,
              [prev]: `${item[prev]}`
            };
            return acc;
          }, {});
        });

      if (config?.item_name_if_compressed === 'sender') {
        if (config?.item_name_if_compressed_source === 'details') {
          const value =
            senderDetails?.[config.item_name_if_compressed_key] ??
            details?.sender?.name ??
            sender?.name;
          details.items[0].itemname = value;
          details.items[0].desc = value;
        } else if (config?.item_name_if_compressed_source === 'kvs') {
          const value =
            senderDetails.kvs.find(
              item => item.key === config.item_name_if_compressed_key
            )?.value ??
            details?.sender?.name ??
            sender?.name;

          details.items[0].itemname = value;
          details.items[0].desc = value;
        } else {
          details.items[0].itemname = details?.sender?.name ?? sender?.name;
          details.items[0].desc = details?.sender?.name ?? sender?.name;
        }
      } else if (config?.item_name_if_compressed === 'jv_details') {
        const value =
          jv?.[config.item_name_if_compressed_key] ??
          details?.sender?.name ??
          sender?.name;
        details.items[0].itemname = value;
        details.items[0].desc = value;
      } else if (config?.item_name_if_compressed === 'jv_kvs') {
        const value =
          jv.kvs.find(item => item.key === config.item_name_if_compressed_key)
            ?.value ??
          details?.sender?.name ??
          sender?.name;

        details.items[0].itemname = value;
        details.items[0].desc = value;
      } else {
        details.items[0].itemname =
          jv?.[config.item_name_if_compressed] ?? details.items[0].itemname;
        details.items[0].desc =
          jv?.[config.item_name_if_compressed] ?? details.items[0].sProd;
      }
    } else {
      details.items = items.map(item => {
        return Object.keys(item).reduce((acc, prev) => {
          acc = {
            ...acc,
            [prev]: `${item[prev]}`
          };

          return acc;
        }, {});
      });
    }

    details.txlogisticid = jv?.RefNo ?? '';

    if (config?.product_as_remark) {
      details.remark = (jv?.trans_inv?.items ?? [])
        .map(props => formatItem(props, config?.product_as_remark_format))
        .join(config?.product_as_remark_delimeter);
    } else details.remark = jv?.[config?.remark] ?? jv?.Remarks;

    const weight = jv?.kvs.find(item => item.key === config.weight)?.value ?? 0;
    details.weight = `${weight}`;

    const now = moment();

    details.createordertime = now.format('YYYY-MM-DD HH:mm:ss');

    // best pickup time
    now.add(config?.best_pick_up_time_days_offset ?? 0, 'days');
    details.sendstarttime = now
      .startOf('day')
      .hour(config?.best_pick_up_start_time ?? BEST_PICK_UP_DEFAULT_START_TIME)
      .format('YYYY-MM-DD HH:mm:ss');

    details.sendendtime = now
      .startOf('day')
      .hour(config?.best_pick_up_end_time ?? BEST_PICK_UP_DEFAULT_END_TIME)
      .format('YYYY-MM-DD HH:mm:ss');

    return details;
  }

  return {
    createOrder,
    trackOrder,
    trackOrders,
    getOrderDetails
  };
}

export default useJNTApi;
