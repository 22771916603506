import React from 'react';
import {
  Box,
  IconButton,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  makeStyles,
  Chip
} from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import FileWithTag from './FileWithTag';

const FILE_ICONS = {
  csv: '/static/images/file_types/csv.png',
  xls: '/static/images/file_types/excel.png',
  xlsx: '/static/images/file_types/excel.png',
  exe: '/static/images/file_types/exe.png',
  json: '/static/images/file_types/json.png',
  pdf: '/static/images/file_types/pdf.png',
  rar: '/static/images/file_types/rar.png',
  zip: '/static/images/file_types/zip.png',
  tar: '/static/images/file_types/tar.png',
  gzip: '/static/images/file_types/gzip.png',
  txt: '/static/images/file_types/txt.png',
  docs: '/static/images/file_types/word.png',
  docx: '/static/images/file_types/word.png',
  mp4: '/static/images/file_types/mp4.png'
};

const IMAGES = ['png', 'jpg', 'jpeg'];

const useStyles = makeStyles(() => ({
  iconImg: {
    backgroundSize: 'contain',
    height: 150
  },
  cardActions: {
    paddingTop: '1em'
  }
}));

const getFileIcon = filename => {
  const fileType = filename.split('.')[1];

  return FILE_ICONS?.[fileType] ?? '/static/images/file_types/unknown.png';
};

const FileCard = ({
  fileName,
  title,
  fileValue,
  tag,
  onDelete,
  onChangeFileWithTag
}) => {
  const classes = useStyles();
  const fileType = fileName.split('.')[1];

  const getImage = () => {
    if (IMAGES.includes(fileType)) {
      return URL.createObjectURL(fileValue);
    }

    return getFileIcon(fileName);
  };

  if (!fileValue)
    return <FileWithTag tag={tag} uploadImage={onChangeFileWithTag} />;

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'body2'
        }}
        action={
          <IconButton size="small" onClick={onDelete}>
            <ClearOutlinedIcon />
          </IconButton>
        }
      />
      <CardMedia
        image={getImage()}
        classes={{
          root: classes.iconImg
        }}
      />
      <CardActions className={classes.cardActions}>
        {tag !== '' && (
          <Box p={1}>
            <Chip color="primary" label={tag} style={{ fontWeight: 700 }} />
          </Box>
        )}
      </CardActions>
    </Card>
  );
};

export default FileCard;
