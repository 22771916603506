import React from 'react';
import { Grid } from '@material-ui/core';
import FileCard from './FileCard';
import { FileUploadBox } from './FileUpload';

const FileList = ({ attachments, onChange, onChangeFileWithTag, onDelete }) => {
  return (
    <Grid container spacing={2}>
      {attachments.map((attachment, index) => (
        <Grid item key={attachment.key} xs={12} sm={12} md={3} xl={3} lg={3}>
          <FileCard
            {...attachment}
            onChangeFileWithTag={(event, tag) =>
              onChangeFileWithTag(event, tag, index)
            }
            onDelete={() => onDelete(index)}
          />
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
        <FileUploadBox onChange={onChange} />
      </Grid>
    </Grid>
  );
};

export default FileList;
