import React, { useState } from 'react';
import {
  Dialog,
  Box,
  DialogActions,
  DialogTitle,
  IconButton,
  Chip,
  Typography
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useNotif } from 'src/hooks';
import { isNumber } from 'lodash';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {VoidFunction} props.onClose
 * @param {string} props.value
 * @returns
 */
const QrCode = ({ open = false, onClose = () => {}, value = '' }) => {
  const [copied, setCopied] = useState(false);
  const dataValue = value.prepend + value.value + value.append;
  const qrValue = String(dataValue);
  const notify = useNotif();

  const handleCopy = () => {
    navigator.clipboard.writeText(value.value);
    setCopied(true);
    notify.success('Copied!');

    setTimeout(() => setCopied(false), 500);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box paddingTop={1} paddingBottom={4} paddingLeft={4} paddingRight={4}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <QRCode value={isNumber(qrValue) ? qrValue.toString() : qrValue} />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            QR Code Details
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={1}
        >
          <Typography variant="body2">{value.value}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={4}
        >
          {value?.showCopy && (
            <DialogActions>
              {copied ? (
                <DoneAllIcon
                  color="primary"
                  style={{ fontSize: 30, color: 'yellowgreen' }}
                />
              ) : (
                <Chip
                  label="Copy"
                  color="primary"
                  icon={<FileCopyIcon />}
                  size="small"
                  onClick={handleCopy}
                />
              )}
            </DialogActions>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(QrCode);
