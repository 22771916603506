import { useEffect, useState } from 'react';
import { useNotif } from 'src/hooks';
import { v4 as uuid } from 'uuid';
import { renameFile } from '../utils';
import { ALLOWED_FILES } from '../constant';
import { isFunction } from 'lodash';

/**
 * useAttachments hook
 * @description useAttachments hook for handling attachments
 * @returns {object} attachments, handlePasteItem, handleDropItem, handleChangeFile, handleChangeFileWithTag, handleDeleteAttachments
 */
const useAttachments = (maxFileSize, maxFiles, getFiles) => {
  const notif = useNotif();

  const [attachments, setAttachments] = useState([]);

  const handleDeleteAttachments = index => {
    setAttachments(prev => {
      if (prev[index].tag !== '') {
        const copy = [...prev];
        copy[index].fileName = '';
        copy[index].title = '';
        copy[index].fileValue = null;
        return copy;
      }

      return prev.filter((_, i) => i !== index);
    });
  };

  const getFile = (files, tag = '', index = -1) => {
    const fileValues = Object.values(files);

    if (Array.isArray(fileValues) && fileValues.length > maxFiles) {
      notif.error(`You can only upload a maximum of ${maxFiles} files`);
      return;
    }

    for (const fileValue of fileValues) {
      const newFilename = renameFile(fileValue.name).toLowerCase();
      const fileNames = attachments.map(item => item.fileName.toLowerCase());

      if (!ALLOWED_FILES.includes(fileValue.type)) {
        notif.error(`Invalid file type : "${fileValue.name}"`);
        return;
      }

      if (fileNames.includes(newFilename)) {
        notif.error(`File "${fileValue.name}" already exists`);
        return;
      }

      if (fileValue.size > maxFileSize) {
        notif.error(
          `File "${
            fileValue.name
          }" exceeds the maximum file size. ${maxFileSize / 1024 / 1024}MB`
        );
        return;
      }

      if (attachments.length >= maxFiles) {
        notif.error(`You can only upload a maximum of ${maxFiles} files`);
        return;
      }

      setAttachments(prev => {
        const copy = [...prev];
        if (index === -1) {
          copy.push({
            fileName: newFilename,
            title: fileValue.name,
            fileValue,
            tag,
            key: uuid()
          });
        } else {
          copy[index] = {
            fileName: newFilename,
            title: fileValue.name,
            fileValue,
            tag
          };
        }
        return copy;
      });
    }
  };

  const handleDropItem = files => {
    getFile(files);
  };
  const handlePasteItem = files => {
    getFile(files);
  };
  const handleChangeFile = files => {
    getFile(files);
  };
  const handleChangeFileWithTag = files => {
    getFile(files);
  };

  useEffect(() => {
    if (!attachments.length) return;
    if (isFunction(getFiles)) getFiles(attachments);
  }, [attachments]);

  return {
    attachments,
    handlePasteItem,
    handleDropItem,
    handleChangeFile,
    handleChangeFileWithTag,
    handleDeleteAttachments
  };
};

export default useAttachments;
