import { useState } from 'react';

const useQrCode = () => {
  const [qrValue, setQrValue] = useState('');

  /**
   *
   * @param {number|string} value
   */
  async function viewQrCode(value) {
    if (!value) return;
    setQrValue(value);
  }

  function closeQrCode() {
    setQrValue('');
  }

  return { viewQrCode, closeQrCode, qrValue };
};

export default useQrCode;
