import React, { useState, useRef, useEffect } from 'react';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    position: 'relative',
    border: '5px dashed #e6e6e6',
    padding: theme.spacing(2)
  },
  fileInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  }
}));

const DragAndDropComponent = ({ onDropItem, onPaste, children }) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const ref = useRef(null);

  const handleOnDropItem = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDropItem(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    if (dragging) return;
    else setDragging(true);
  };

  const handlePaste = e => {
    e.preventDefault();
    e.stopPropagation();

    onPaste(e.clipboardData.files);
  };

  return (
    <Box ref={ref} className={classes.root}>
      <input
        type="text"
        className={classes.fileInput}
        onDrop={handleOnDropItem}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onPaste={handlePaste}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      />
      {children}
    </Box>
  );
};

export default DragAndDropComponent;
