import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
  MenuItem,
  Button,
  DialogActions,
  FormHelperText
} from '@material-ui/core';
import useAddress from '../useAddress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAddressOptions } from '../StracturedAddressData';
import { toUpper } from 'lodash';
import { de } from 'date-fns/locale';

const AddressModal = ({
  formattedData,
  openDialog,
  openDlg,
  closeDialog,
  onEntert,
  onsubmitData
}) => {
  const [inputAddress, setInputAddress] = useState(onEntert || '');
  const [submitModalAddress, setSubmitModalAddress] = useState('');
  const [delimiter, setDelimiter] = useState(',');
  const [errorMessage, setErrorMessage] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { prov, city, barangay, zip, street } = useAddress({
    formattedData,
    handleSetDelimiter: delimiter,
    onEntert: submitModalAddress
  });

  const [selectedProvince, setSelectedProvince] = useState('');
  const [inputValueProvince, setInputValueProvince] = useState('');
  const [errorMessageProvince, setErrorMessageProvince] = useState(false);

  const [selectedCity, setSelectedCity] = useState('');
  const [inputValueCity, setInputValueCity] = useState('');
  const [errorMessageCity, setErrorMessageCity] = useState(false);

  const [selectedBarangay, setSelectedBarangay] = useState('');
  const [inputValueBarangay, setInputValueBarangay] = useState('');
  const [errorMessageBarangay, setErrorMessageBarangay] = useState(false);

  const [choosenZipCode, setChosenZipCode] = useState('');
  const [choosenStreet, setChosenStreet] = useState('');

  const addressOptions = getAddressOptions(
    formattedData,
    `${selectedProvince}` || `${prov}` || '',
    `${selectedCity}` || `${city}` || ''
  );

  const handleInputText = e => {
    setInputAddress(e.target.value);
    setErrorMessage(true);
  };
  const handleInputProvince = e => {
    setSelectedProvince(e.target.value);
  };
  const handleInputCity = e => {
    setSelectedCity(e.target.value);
  };
  const handleInputBarangay = e => {
    setSelectedBarangay(e.target.value);
  };
  const handleInputZip = e => {
    const text = toUpper(e.target.value);
    setChosenZipCode(text);
  };
  const handleInputStreet = e => {
    const text = toUpper(e.target.value);
    setChosenStreet(text);
  };

  const handleInputAddresses = async () => {
    setSubmitModalAddress(inputAddress);
    setErrorMessage(false);
  };

  const handleInputDelimiter = e => {
    setDelimiter(e.target.value);
  };

  const handleFocus = () => {
    proccessedAddress();
  };

  const proccessedAddress = () => {
    if (prov) {
      handleInputProvince({ target: { value: prov } });
      setErrorMessageProvince(false);

      if (!city) setErrorMessageCity(true);
      if (!barangay) setErrorMessageBarangay(true);
    } else {
      handleInputProvince({ target: { value: '' } });
      setErrorMessageProvince(true);
    }

    if (city) {
      handleInputCity({ target: { value: city } });
      setErrorMessageCity(false);

      if (!barangay) setErrorMessageBarangay(true);
    } else {
      handleInputCity({ target: { value: '' } });
      setErrorMessageCity(true);
    }

    if (barangay) {
      handleInputBarangay({ target: { value: barangay } });
      setErrorMessageBarangay(false);
    } else {
      handleInputBarangay({ target: { value: '' } });
      setErrorMessageBarangay(true);
    }

    if (zip) {
      handleInputZip({ target: { value: zip } });
    } else {
      handleInputZip({ target: { value: '' } });
    }

    if (street) {
      handleInputStreet({ target: { value: street } });
    } else {
      handleInputStreet({ target: { value: '' } });
    }
  };

  const handleClose = () => {
    setInputAddress('');
    setSelectedProvince('');
    setSelectedCity('');
    setSelectedBarangay('');
    setChosenZipCode('');
    setChosenStreet('');
    closeDialog();
  };

  const handleSave = () => {
    onsubmitData({
      addProvince: selectedProvince,
      addMuniCities: selectedCity,
      addBarangays: selectedBarangay,
      addZIP: choosenZipCode,
      addStreet: choosenStreet
    });

    handleClose();
  };

  useEffect(() => {
    if (submitModalAddress) {
      proccessedAddress();
    }
    if (onEntert) {
      proccessedAddress();
    }
  }, [submitModalAddress, onEntert]);

  return (
    <Dialog
      open={openDialog || openDlg}
      maxWidth="sm"
      fullWidth
      aria-labelledby="word-arrange-modal"
      aria-describedby="word-arrange-description"
    >
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <DialogTitle disableTypography>
            <Typography variant="h4" component="p" color="textSecondary">
              Verify Address
            </Typography>
            <Typography variant="subtitle2" component="p" color="textSecondary">
              Please verify the address before proceeding.
            </Typography>
          </DialogTitle>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}></Grid>
          <Grid
            container
            spacing={2}
            style={{ paddingTop: 40, paddingLeft: 60 }}
          >
            <TextField
              select
              variant="outlined"
              label="Delimiter"
              value={delimiter}
              onChange={handleInputDelimiter}
              size="small"
              sx={{ width: 120 }}
              maxWidth={120}
            >
              <MenuItem value=",">Comma (,)</MenuItem>
              <MenuItem value=".">Period (.)</MenuItem>
              <MenuItem value=";">Colon (;)</MenuItem>
              <MenuItem value="|">Pipe (|)</MenuItem>
              <MenuItem value="\t">Tab</MenuItem>
              <MenuItem value=" ">Space</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <DialogContent style={{ padding: 16 }}>
        <Box display="flex" flexDirection="column" gridGap={16}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Paste Here"
                value={inputAddress}
                onChange={handleInputText}
                onFocus={e => {
                  handleFocus();
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleInputAddresses();
                  }
                }}
                error={!!errorMessage}
                helperText={errorMessage ? 'Please Press Enter to Submit' : ''}
              />
            </Grid>
          </Grid>
          <Autocomplete
            options={addressOptions?.provinces || []}
            value={selectedProvince}
            onChange={(event, newValue) => {
              setSelectedProvince(newValue || '');
              setSelectedCity('');
              setSelectedBarangay('');
              setErrorMessageProvince(!newValue);
              setErrorMessageCity(true);
              setErrorMessageBarangay(true);
            }}
            inputValue={inputValueProvince}
            onInputChange={(event, newInputValue) => {
              setInputValueProvince(newInputValue);
            }}
            onBlur={() => {
              if (!selectedProvince) setErrorMessageProvince(true);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Province"
                variant="outlined"
                error={errorMessageProvince}
                helperText={
                  errorMessageProvince
                    ? 'Empty or Not Found in Address Input'
                    : ''
                }
              />
            )}
          />

          <Autocomplete
            options={addressOptions?.townCities || []}
            value={selectedCity}
            onChange={(event, newValue) => {
              setSelectedCity(newValue || '');
              setSelectedBarangay('');
              setErrorMessageCity(!newValue);
              setErrorMessageBarangay(true);
            }}
            inputValue={inputValueCity}
            onInputChange={(event, newInputValue) => {
              setInputValueCity(newInputValue);
            }}
            onBlur={() => {
              if (!selectedCity) setErrorMessageCity(true);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="City/Municipality"
                variant="outlined"
                error={errorMessageCity}
                helperText={
                  errorMessageCity ? 'Empty or Not Found in Address Input' : ''
                }
              />
            )}
          />

          <Autocomplete
            options={addressOptions?.barangays || []}
            value={selectedBarangay}
            onChange={(event, newValue) => {
              setSelectedBarangay(newValue || '');
              setErrorMessageBarangay(!newValue);
            }}
            inputValue={inputValueBarangay}
            onInputChange={(event, newInputValue) => {
              setInputValueBarangay(newInputValue);
            }}
            onBlur={() => {
              if (!selectedBarangay) setErrorMessageBarangay(true);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Barangay"
                variant="outlined"
                error={errorMessageBarangay}
                helperText={
                  errorMessageBarangay
                    ? 'Empty or Not Found in Address Input'
                    : ''
                }
              />
            )}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="Zip Code"
            value={choosenZipCode}
            onChange={handleInputZip}
            type="number"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Street"
            value={choosenStreet}
            onChange={handleInputStreet}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Fix Address"
            value={`${selectedProvince}, ${selectedCity}, ${selectedBarangay}, ${choosenZipCode}, ${choosenStreet}`}
            disabled={true}
            rows={3}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={handleSave}
          disabled={
            errorMessageProvince || errorMessageCity || errorMessageBarangay
          }
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;
