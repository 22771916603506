import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  InputAdornment,
  Divider,
  FormHelperText,
  Chip,
  Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DebounceTextField from './DebounceTextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

export default function SelectWithSearchV2({
  value = [],
  options = [],
  label = '',
  searchKeys = [],
  onChange,
  disabled,
  searchPlaceHolder = 'Type to search...',
  CustomMenuItem = null,
  error = false,
  errorMessage = '',
  ChipMaxWidth,
  ...rest
}) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  // Check if all options are selected
  const areAllOptionsSelected = useMemo(() => {
    if (value.length === 0 || options.length === 0) return false;
    const optionValues = options.map(option => option.value);
    return (
      value.length === optionValues.length &&
      value.every(v => optionValues.includes(v))
    );
  }, [value, options]);

  const displayedOptions = useMemo(
    () =>
      options.filter(
        item =>
          !value.includes(item.value) &&
          searchKeys.some(key =>
            item[key]?.toLowerCase().includes(searchText.toLowerCase())
          )
      ),
    [searchText, options, searchKeys, value]
  );

  const handleDelete = deletedValue => {
    if (deletedValue === 'all') {
      onChange({ target: { value: [] } }); // Clear all selections
    } else {
      const updatedValues = value.filter(v => v !== deletedValue);
      onChange({ target: { value: updatedValues } });
    }
  };

  const handleChangeSearchText = e => {
    setSearchText(e.target.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const handleToggleSelectAll = () => {
    if (areAllOptionsSelected) {
      onChange({ target: { value: [] } });
    } else {
      const allValues = options.map(option => option.value);
      onChange({ target: { value: allValues } });
    }
  };

  return (
    <FormControl error={error} variant="outlined" fullWidth {...rest}>
      <InputLabel id="search-select-label">{label}</InputLabel>
      <Select
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        onClose={clearSearchText}
        multiple
        renderValue={selected =>
          areAllOptionsSelected
            ? 'All items selected'
            : selected
                .map(
                  val => options.find(item => item.value === val)?.description
                )
                .join(', ') || ''
        }
      >
        <ListSubheader disableGutters>
          <Paper component={Box} elevation={0}>
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth={500}
              p={0.5}
              sx={{ maxHeight: 300, overflow: 'auto' }}
            >
              {areAllOptionsSelected ? (
                <Chip
                  color="primary"
                  label="All items selected"
                  className={classes.chip}
                  onDelete={() => handleDelete('all')}
                  size="small"
                />
              ) : (
                value.map(selectedValue => {
                  const selectedOption = options.find(
                    option => option.value === selectedValue
                  );

                  return (
                    <Chip
                      key={selectedValue}
                      color="primary"
                      label={selectedOption?.description || selectedValue}
                      className={classes.chip}
                      onDelete={() => handleDelete(selectedValue)}
                      size="small"
                    />
                  );
                })
              )}
            </Box>
          </Paper>
          <Divider />
          <Paper
            component={Box}
            elevation={0}
            display="flex"
            alignItems="center"
            p={2}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DebounceTextField
              size="small"
              autoFocus
              placeholder={searchPlaceHolder}
              fullWidth
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleChangeSearchText}
              onKeyDown={e => {
                if (e.key !== 'Escape') e.stopPropagation();
              }}
            />
            {!areAllOptionsSelected && (
              <Button
                variant="contained"
                fontSize="small"
                onClick={handleToggleSelectAll}
                style={{ marginLeft: '8px' }}
                size="small"
                color="primary"
              >
                All
              </Button>
            )}
          </Paper>
          <Divider />
        </ListSubheader>

        {displayedOptions.map(({ value, description, ...rest }) =>
          Boolean(CustomMenuItem) ? (
            <CustomMenuItem
              key={value}
              value={value}
              description={description}
              {...rest}
            />
          ) : (
            <MenuItem key={value} value={value}>
              {description}
            </MenuItem>
          )
        )}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
