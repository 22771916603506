import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DataTable } from '..';

/**
 * @typedef {Object} limit
 * @property {string} id
 * @property {number} value
 */

/**
 * @typedef {object} credit_limit
 * @property {string} desc
 * @property {string|number} id
 * @property {number[]} ixAcc
 * @property {number[]} ixJCd
 * @property {number} ixSubType
 * @property {0|1} mode
 * @property {string} sSubType
 */

/**
 * @param {Array<credit_limit>} credit_limits
 * @param {Array<{id:number|string, value:number}>} value
 * @returns {Array<{id: string, desc: string, value: number}>}
 */
function generateTableData(credit_limits = [], value = [], columns = []) {
  const res = [];

  for (const credit_limit of credit_limits) {
    const row = {};

    for (const column of columns) {
      row[column.field] =
        value.find(item => item.id === credit_limit.id)?.[column.field] || '';
    }

    row.id = credit_limit.id;
    row.desc = credit_limit.desc;

    res.push(row);
  }

  return res;
}

const CreditLimit = ({ data, onChangeField }) => {
  const { frontEnd = {} } = useSelector(state => state.bizMeta);

  /**
   * @type {{allow:boolean, credit_limit: Array<credit_limit}>}
   */
  const { allow: allowCreditLimit = false, items: creditLimitItems = [] } =
    frontEnd?.credit_limit || {};

  if (!allowCreditLimit) return null;

  const tableData = generateTableData(
    creditLimitItems,
    data?.value || [],
    data?.columns || []
  );
  console.log('tableData', tableData);

  /**
   * @param {cellParams} params
   */
  function editCell(params) {
    onChangeField({
      target: {
        name: data?.fld || '',
        value: tableData.map(item => {
          if (item.id === params.id) item[params.field] = params.value;

          return item;
        })
      }
    });
  }

  return (
    <Box>
      <DataTable
        data={tableData}
        columns={data?.columns ?? []}
        hideToolbar
        paginate={false}
        onCellEdit={editCell}
      />
    </Box>
  );
};

export default CreditLimit;
