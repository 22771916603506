class LinkedLeave {
  ixDate = 0;
  ixEmp = 0;
  sEmp = '';
  ixPR = 0;
  ixSIL = 0;
  ixSILType = 0;
  ixTime1 = 0;
  ixTime2 = 0;
  qtyIN = 0;
  qtyOUT = 0;
  Remarks = '';

  /**
   * @type {string|number}
   */
  jid;

  constructor({
    ixDate,
    ixEmp,
    sEmp,
    ixPR,
    ixSIL,
    ixSILType,
    ixTime1,
    ixTime2,
    qtyIN,
    qtyOUT,
    Remarks,
    jid
  }) {
    this.ixDate = ixDate;
    this.ixEmp = ixEmp;
    this.sEmp = sEmp;
    this.ixPR = ixPR;
    this.ixSIL = ixSIL;
    this.ixSILType = ixSILType;
    this.ixTime1 = ixTime1;
    this.ixTime2 = ixTime2;
    this.qtyIN = qtyIN;
    this.qtyOUT = qtyOUT;
    this.Remarks = Remarks;
    this.jid = jid;
  }
}

export default LinkedLeave;
