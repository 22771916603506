import React from 'react';
import { Box, Paper, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  boxInput: {
    width: '100%',
    height: '233px',
    minHeight: '233px'
  },
  input: {
    display: 'none'
  },
  button: {
    height: '100%',
    width: '100%'
  }
}));

export function FileUploadButton({ onChange }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        onChange={onChange}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
        >
          Browse
        </Button>
      </label>
    </div>
  );
}

export function FileUploadBox({ onChange }) {
  const classes = useStyles();

  return (
    <Paper component={Box} className={classes.boxInput}>
      <input
        className={classes.input}
        onChange={onChange}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <Button className={classes.button} color="primary" component="span">
          Browse
        </Button>
      </label>
    </Paper>
  );
}
