import React from 'react';
import Boolean from './Boolean';
import Default from './Default';
import MultiSub from './MultiSub';
import Number from './Number';
import NumberWithFormula from './NumberWithFormula';
import Select from './Select';
import DateField from './DateField';
import TableView from './TableView';
import SubAccount from './SubAccount';
import Account from './Account';
import Product from './Product';
import MultiField from './MultiField';
import TimeField from './TimeField';
import ArrayField from './ArrayField';
import Users from './Users';
import TransLink from './TransLink';
import Limits from './Limits';
import Text from './Text';
import { Box } from '@material-ui/core';
import Checkbox from './Checkbox';
import Link from './Link';
import Logs from './Logs';
import BizList from './BizList';
import CustomMaskedInput from './CustomMaskedInput';
import CreditLimit from './CreditLimit';

const FIELD_COMPONENTS = {
  number: Number,
  'number-with-formula': NumberWithFormula,
  select: Select,
  'multi-sub': MultiSub,
  sub_acc: SubAccount,
  account: Account,
  product: Product,
  table: TableView,
  boolean: Boolean,
  date: DateField,
  default: Default,
  multi_flds: MultiField,
  time: TimeField,
  array: ArrayField,
  users: Users,
  trans_link: TransLink,
  limits: Limits,
  text: Text,
  checkbox: Checkbox,
  link: Link,
  logs: Logs,
  biz_list: BizList,
  masked_input: CustomMaskedInput,
  credit_limit: CreditLimit
};

const Field = ({
  data,
  debounce = true,
  debounceDelay = 500,
  onChangeField,
  maxLength = 255,
  endButton = null
}) => {
  const copyData = { ...data };
  const disabledFields = data?.row?.disabledFields ?? [];
  const blankFields = data?.row?.blankFields ?? [];
  const UIComponent =
    FIELD_COMPONENTS?.[data?.ui_component ?? data?.type ?? 'default'] ??
    Default;

  if (
    blankFields.length > 0 &&
    blankFields.includes(data?.field ?? data?.fld ?? '')
  ) {
    return <Box p={2} />;
  }

  if (
    disabledFields.length > 0 &&
    disabledFields.includes(data?.field ?? data?.fld ?? '')
  ) {
    copyData.disabled = true;
  }

  return (
    <UIComponent
      data={copyData}
      debounce={debounce}
      debounceDelay={debounceDelay}
      onChangeField={onChangeField}
      maxLength={maxLength}
      endButton={endButton}
    />
  );
};

export { Boolean, Default, MultiSub, Number, Select };

export default Field;
