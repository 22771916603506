import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputIcon from '@material-ui/icons/Input';
import { Menu } from '@material-ui/icons';
import useSocket from 'src/hooks/useSocket';
import {
  Avatar,
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  useTheme,
  Hidden,
  IconButton
} from '@material-ui/core';
import { logoutPortal } from 'src/redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { NAVBAR_HEIGHT } from 'src/constants';
import { setPortalMeta } from 'src/redux/slices/portal';

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    height: NAVBAR_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1)
  },
  appTitle: {
    paddingLeft: 8
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    borderRadius: 5,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 1,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#ff811a'
    }
  },
  btn: {
    color: '#fff',
    textTransform: 'none',
    borderRadius: 5,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 1,
    '&:hover': {
      backgroundColor: '#ff811a'
    }
  },
  avatar: {
    padding: 2,
    background: '#fff',
    boxShadow: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`
  }
}));

const TopBar = ({ className, toggleSideBar, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { Disconnect } = useSocket();

  const { portal = {} } = useSelector(state => state.auth);

  const { cdBiz = '', cdPortal = '' } = portal;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    Disconnect();
    dispatch(logoutPortal());
    dispatch(setPortalMeta({}));
    navigate(`/portal/${cdBiz}/${cdPortal}/login`);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <RouterLink to="/">
            <Avatar
              src="/static/images/icon_no_white_background.png"
              className={classes.avatar}
            />
          </RouterLink>
          <Typography className={classes.appTitle} align="center" variant="h3">
            Ulap<b>Biz</b>
          </Typography>
          <Box ml={5} display="flex" gridColumnGap={theme.spacing(2)}>
            <Hidden mdDown>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/portal/welcome"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Home
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/portal/profile"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Profile
              </Typography>
            </Hidden>
          </Box>
          <Box flexGrow={1} />
          <Hidden mdDown>
            <Button
              color="inherit"
              startIcon={<InputIcon />}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={toggleSideBar}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
