import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles,
  Collapse,
  Tooltip,
  DialogActions,
  Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SrchProdResults from './SrchProdResults';
import useProdSearch from './useProdSearch';
import { Pagination, Skeleton } from '@material-ui/lab';
import { Search, Cached, Close } from '@material-ui/icons';
import useMenu from 'src/hooks/useMenuV2';
import SelectCategory from './SelectCategory';
import SelectSubCategory from './SelectSubCategory';
import { ceil } from 'lodash';
import { useEffect } from 'react';
import SrchSub from '../SrchSub';
import { showSubLinkFilters } from 'src/helpers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '350px'
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  iconButton: {
    margin: '1px'
  },
  adornStart: {
    paddingLeft: '1px'
  },

  dialogContent: {
    padding: 0,
    height: 500,
    margin: 0
  }
}));

const PRODUCT_PER_PAGE = 20;

const ItemLoading = () => {
  return (
    <Box display="flex" gridGap="1em" mb="1em">
      <Skeleton variant="rect" animation="wave" width={180} height={130} />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        p={1}
      >
        <Typography variant="h1" color="primary">
          <Skeleton variant="text" />
        </Typography>
        <Typography variant="caption" color="primary">
          <Skeleton variant="text" />
        </Typography>
      </Box>
    </Box>
  );
};

const SrchProd = ({
  sProd = '',
  ixProd = 0,
  handleProdSelectRoot = () => null,
  error = false,
  errorMessage = false,
  disabled = false,
  endAction = null,
  showPrice = false,
  priceProps = 'cPrice1',
  showInactiveProd = true,
  ...rest
}) => {
  const labelsSetings = useSelector(state => state.bizMeta);
  const label = labelsSetings?.frontEnd?.labels?.products?.prodlist;
  const theme = useTheme();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    loading,
    hasError,
    products,
    query,
    setQuery,
    productCategory,
    productSubCategory,
    productCategories,
    productSubCategories,
    setProductCategory,
    setProductSubCategory,
    refresh,
    prodSubLink,
    subLinks,
    setSubLinks
  } = useProdSearch({ filters: rest.filters, showInactiveProd });

  const [catAnchorEl, openCategory, closeCategory] = useMenu();
  const [subCatAnchorEl, openSubCategory, closeSubCategory] = useMenu();

  const handleSearch = e => {
    setQuery(e.target.value);
  };

  const handleSrchProd = () => {
    setOpen(true);
  };

  const handleProdSelect = item => {
    setOpen(false);
    handleProdSelectRoot({ ...item });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paginatedProducts = useMemo(() => {
    return products.slice(
      currentPage * PRODUCT_PER_PAGE - PRODUCT_PER_PAGE,
      currentPage * PRODUCT_PER_PAGE
    );
  }, [products, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [productCategory, productSubCategory, query]);

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        fullScreen={smScreen}
      >
        <DialogTitle>
          <Box>
            <TextField
              fullWidth
              value={query}
              onChange={handleSearch}
              autoComplete="off"
              autoFocus
              InputProps={{
                disableUnderline: true,
                autoFocus: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action">
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Tooltip title="Refresh" arrow>
                        <span>
                          <IconButton onClick={refresh} size="small">
                            <Cached />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Tooltip title="Refresh" arrow>
                        <span>
                          <IconButton onClick={handleClose} size="small">
                            <Close />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  </>
                )
              }}
              placeholder="Search Product"
              variant="standard"
            />

            <Box display="flex" gridGap="1em" mt={2}>
              <SelectCategory
                openCategory={openCategory}
                closeCategory={closeCategory}
                productCategories={productCategories}
                productCategory={productCategory}
                catAnchorEl={catAnchorEl}
                setProductCategory={item => {
                  setProductCategory(item);
                }}
                setProductSubCategory={setProductSubCategory}
              />
              <SelectSubCategory
                openSubCategory={openSubCategory}
                closeSubCategory={closeSubCategory}
                productSubCategories={productSubCategories}
                productSubCategory={productSubCategory}
                subCatAnchorEl={subCatAnchorEl}
                productCategory={productCategory}
                setProductSubCategory={setProductSubCategory}
              />
            </Box>

            {showSubLinkFilters(prodSubLink, productCategory) && (
              <Box display="flex" flexDirection="column" gridGap=".5em" mt={2}>
                <Typography variant="h5" color="textSecondary">
                  Filter By
                </Typography>
                <Box>
                  <Grid container spacing={1}>
                    {[1, 2, 3, 4, 5].map(item =>
                      (
                        prodSubLink?.[`${item}`]?.['require-on-category'] || []
                      ).includes(productCategory) ? (
                        <Grid
                          item
                          key={item}
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <SrchSub
                            ixSubType={prodSubLink[`${item}`].ixSubType}
                            sSubType={prodSubLink[`${item}`]?.caption ?? ''}
                            selectedSub={subLinks[`sSubLink${item}`]}
                            handleSubSelectRoot={sub => {
                              setSubLinks(prev => {
                                return {
                                  ...prev,
                                  ['ixSubLink' + item]:
                                    sub.ixSub === '' ? 0 : sub.ixSub,
                                  ['sSubLink' + item]: sub.sSub
                                };
                              });
                            }}
                            size="small"
                            placeholder={
                              'Select ' + prodSubLink[`${item}`]?.caption ?? ''
                            }
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          <Collapse in={hasError}>
            <Box mt={1}>
              <Alert severity="error">Something went wrong.</Alert>
            </Box>
          </Collapse>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {loading && (
            <Box padding="1em">
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
            </Box>
          )}

          {!loading && products.length === 0 && query === '' && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <SearchIcon color="primary" fontSize="large" />
                <Typography variant="h5" color="primary">
                  Search Product
                </Typography>
              </Box>
            </Box>
          )}

          {!loading && products.length === 0 && query.trim() !== '' && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <SearchIcon color="primary" fontSize="large" />
                <Typography variant="h5" color="primary">
                  No Result Found
                </Typography>
              </Box>
            </Box>
          )}

          {!loading && products.length > 0 && (
            <>
              <SrchProdResults
                prodList={paginatedProducts}
                handleProdSelect={handleProdSelect}
                showPrice={showPrice}
                priceProps={priceProps}
              />
            </>
          )}
        </DialogContent>
        {products.length > PRODUCT_PER_PAGE && (
          <>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
              <Pagination
                count={ceil(products.length / PRODUCT_PER_PAGE)}
                color="primary"
                page={currentPage}
                onChange={(_, val) => setCurrentPage(val)}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
      <Box>
        <TextField
          name="SrchProd"
          value={sProd}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={handleSrchProd}
                  disabled={disabled}
                  {...rest?.textBoxProps}
                  {...rest?.startIconProps}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: endAction ? (
              <InputAdornment position="end" {...rest?.startIconProps}>
                {endAction}
              </InputAdornment>
            ) : null,
            classes: {
              adornedStart: classes.adornStart
            }
          }}
          placeholder={label ? `Search ${label}` : 'Search Product'}
          label={label || 'Product'}
          variant="outlined"
          onChange={handleSrchProd}
          error={error}
          helperText={errorMessage}
          disabled={disabled}
          fullWidth
          {...rest?.textBoxProps}
        />
      </Box>
    </Box>
  );
};

SrchProd.propTypes = {
  className: PropTypes.string
};

export default SrchProd;
