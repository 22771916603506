import { escapeRegExp } from 'lodash';

function formatValue(value) {
  if (!value) return '';

  return value
    .toString()
    .trim()
    .toLowerCase();
}

function like(item, fields, value) {
  return fields.some(fld =>
    formatValue(item[fld]).includes(formatValue(value))
  );
}

function equals(item, fields, value) {
  return fields.some(fld => formatValue(item[fld]) === formatValue(value));
}

function filterAdvance({ data = [], value = '', operator = '', fields = [] }) {
  if (operator === '' || fields.length === 0) return data;
  else
    return data.filter(item => {
      if (operator === 'Like') return like(item, fields, value);
      else if (operator === 'Equals') return equals(item, fields, value);
      else if (operator === 'Not Equals') return !equals(item, fields, value);
      else return true;
    });
}

function filterTableData(
  data = [],
  filters = {},
  searchFilter = '',
  advancedFilter = {}
) {
  const searchRegEx = new RegExp(escapeRegExp(searchFilter), 'i');

  return filterAdvance({
    data,
    value: advancedFilter.value,
    operator: advancedFilter.operator,
    fields: advancedFilter.fields
  }).filter(item => {
    for (const key of Object.keys(filters)) {
      if (filters[key] !== 'all' && item[key] !== filters[key]) {
        return false;
      }
    }

    return Object.keys(item).some(field =>
      searchRegEx.test((item?.[field] || '').toString())
    );
  });
}

export default filterTableData;
