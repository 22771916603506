import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Chip,
  Grid,
  Card,
  CardContent
} from '@material-ui/core';
import { useNotif } from 'src/hooks';

const fileTagStyles = makeStyles({
  paste: {
    position: 'relative',
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    cursor: 'pointer'
  },
  fileInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  }
});

const FileWithTag = ({ tag = '', uploadImage = () => {} }) => {
  const classNames = fileTagStyles();
  const notify = useNotif();

  const [isOver, setIsOver] = useState();

  const dropHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    const droppedItems = event.dataTransfer.items;

    if (droppedItems.length === 0) {
      notify.error('Please upload a valid file.');
      return;
    }

    uploadImage(
      {
        target: {
          files: event.dataTransfer.files
        }
      },
      tag
    );
  };

  const changeHandler = event => {
    event.preventDefault();
    event.stopPropagation();

    const items = event.target.files;

    if (items.length === 0) {
      notify.error('Please upload a valid file.');
      return;
    }

    uploadImage(event, tag);
  };

  return (
    <Card style={{ height: 286 }}>
      <CardContent style={{ height: '100%' }}>
        <Box
          className={classNames.paste}
          border={isOver ? '4px dashed #608921' : '4px dashed rgba(0,0,0,.1)'}
        >
          <input
            type="file"
            className={classNames.fileInput}
            onDrop={dropHandler}
            onChange={changeHandler}
            onDragEnter={() => setIsOver(true)}
            onDragLeave={() => setIsOver(false)}
          />
          <Typography variant="body1" color="textSecondary">
            Drag or click here.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Chip color="primary" label={tag} style={{ fontWeight: 700 }} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default FileWithTag;
