import {
  blue,
  green,
  orange,
  purple,
  red,
  teal,
  lime
} from '@material-ui/core/colors';

export const APP_VERSION = '1.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  DEFAULT: 'DEFAULT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  PURPLE: 'PURPLE',
  FLAMINGO: 'FLAMINGO',
  LIME: 'LIME',
  MINT: 'MINT',
  AQUA: 'AQUA',
  COZY: 'COZY',
  FIRE_BREATH: 'FIRE_BREATH',
  IBFRS_THEME: 'IBFRS_THEME',
  SUN_FLOWER: 'SUN_FLOWER'
};

export const SIDEBAR_WIDTH = 256;
export const NAVBAR_HEIGHT = 64;
export const MAX_RECENT_TO_DISPLAY = 4;
export const MAX_BIZ_PER_PAGE = 12;

export const EXCLUDED_REDUCERS = [
  'bizState',
  'auth',
  'registrationReducer',
  'global',
  'registry',
  'announcement',
  'env'
];

export const EXPENSE_CLASS = {
  1: {
    desc: 'Personnel Services',
    shortDesc: 'PS'
  },
  2: { desc: 'Maintenance and Other Operating Services', shortDesc: 'MOOE' },
  3: { desc: 'Financial Expenses', shortDesc: 'FE' },
  4: { desc: 'Capital Outlay', shortDesc: 'CO' }
};

export const JSTATUS = {
  0: 'For Approval',
  1: 'Approved',
  2: 'Disapproved',
  3: 'Cancelled'
};

export const JSTATUS_COLOR = {
  0: blue[400],
  1: green[400],
  2: red[400],
  3: orange[400]
};

export const RU_PRIV_OVERRIDE = ['biz', 'acc', 'user-priv', 'portal'];

export const ULAP_CONFIG = {
  default: {
    name: {
      first_word: 'Ulap',
      second_word: 'Biz'
    },
    logo_url: {
      small: '/static/images/icon_no_white_background.png',
      regular: '/static/images/icon_no_white_background.png',
      medium: '/static/images/icon_no_white_background.png',
      large: '/static/images/icon_no_white_background.png'
    },
    logo_settings: {
      padding: 2,
      background: '#FFF'
    }
  },
  egov: {
    name: {
      first_word: 'i',
      second_word: 'BFRS'
    },
    logo_url: {
      small: '/static/images/iBFRS_transparent.png',
      regular: '/static/images/iBFRS_transparent.png',
      medium: '/static/images/iBFRS_transparent.png',
      large: '/static/images/iBFRS_transparent.png'
    },
    logo_settings: {
      padding: 0,
      background: 'none'
    }
  },
  radztech: {
    name: {
      first_word: '',
      second_word: 'Radztech'
    },
    logo_url: {
      small: '/static/images/radztech_logo.png',
      regular: '/static/images/radztech_logo.png',
      medium: '/static/images/radztech_logo.png',
      large: '/static/images/radztech_logo.png'
    },
    logo_settings: {
      padding: 0,
      background: 'none'
    }
  },
  student: {}
};

export const Timezone = '+08:00';

export const EXP_CLS_COLORS = {
  ps: '#003f5c',
  mooe: '#58508d',
  fe: '#bc5090',
  co: '#ff6361'
};

export const BUDGET_TYPE_COLORS = {
  approp: '#7DA42D',
  appropAdj: '#9932CC',
  allot: '#007AC3',
  oblig: '#FEC659',
  supple: '#FFA500',
  dv: '#B73535'
};

export const BUDGET_TYPE_2_COLORS = {
  approp: '#3366D6',
  allot: '#4285F4',
  oblig: '#A0C2FA',
  dv: '#D0E0FC'
};

export const SIL_TYPES = {
  SICK_LEAVE: 1,
  VACATION_LEAVE: 2,
  MATERNITY_LEAVE: 3,
  PATERNITY_LEAVE: 4,
  BEREAVEMENT_LEAVE: 5,
  UNPAID_LEAVE: 6,
  SIL: 7,
  PRIVILEGE_LEAVE: 8,
  OTHERS: 9,
  OB: 21,
  OT: 22,
  SELFIE_LOGIN: 23,
  ATT_CORRECTION: 31
};

export const LEAVES = [
  SIL_TYPES.SICK_LEAVE,
  SIL_TYPES.VACATION_LEAVE,
  SIL_TYPES.MATERNITY_LEAVE,
  SIL_TYPES.PATERNITY_LEAVE,
  SIL_TYPES.BEREAVEMENT_LEAVE,
  SIL_TYPES.UNPAID_LEAVE,
  SIL_TYPES.SIL,
  SIL_TYPES.PRIVILEGE_LEAVE
];

export const LEAVES_W_BAL = [
  SIL_TYPES.SICK_LEAVE,
  SIL_TYPES.VACATION_LEAVE,
  SIL_TYPES.SIL,
  SIL_TYPES.PRIVILEGE_LEAVE
];

export const PAID_LEAVES = [
  SIL_TYPES.SICK_LEAVE,
  SIL_TYPES.VACATION_LEAVE,
  SIL_TYPES.SIL,
  SIL_TYPES.PRIVILEGE_LEAVE
];

export const SIL_TYPES_W_TIME1 = [SIL_TYPES.ATT_CORRECTION];

export const SIL_TYPES_W_TIME = [
  SIL_TYPES.OB,
  SIL_TYPES.OT,
  SIL_TYPES.SELFIE_LOGIN
];

export const SIL_TYPE_DESCRIPTION = {
  [SIL_TYPES.SICK_LEAVE]: 'Sick Leave',
  [SIL_TYPES.VACATION_LEAVE]: 'Vacation Leave',
  [SIL_TYPES.MATERNITY_LEAVE]: 'Maternity Leave',
  [SIL_TYPES.PATERNITY_LEAVE]: 'Paternity Leave',
  [SIL_TYPES.BEREAVEMENT_LEAVE]: 'Bereavement Leave',
  [SIL_TYPES.UNPAID_LEAVE]: 'Unpaid Leave',
  [SIL_TYPES.OTHERS]: 'Others',
  [SIL_TYPES.OB]: 'Official Business',
  [SIL_TYPES.OT]: 'Overtime',
  [SIL_TYPES.SIL]: 'Service Incentive Leave',
  [SIL_TYPES.PRIVILEGE_LEAVE]: 'Privilege Leave',
  [SIL_TYPES.SELFIE_LOGIN]: 'Selfie Login',
  [SIL_TYPES.ATT_CORRECTION]: 'Attendance Correction'
};

export const SIL_MODE = {
  SIL_REQUEST: 1,
  SIL_CREDIT_SETUP: 2,
  SIL_RECLASS: 3
};

export const SIL_CREDIT_SETUP_UI_MODE = {
  DEFAULT: 1,
  BY_MONTH: 2
};

export const HOLIDAY_LEVELS = {
  GLOBAL: 1,
  BIZ: 2,
  MERGED: 3
};

export const HOLIDAY_TYPES = {
  REGULAR_DAY: 0,
  REGULAR_HOLIDAY: 1,
  SPECIAL_HOLIDAY: 2
};

export const COLOR_VALUES = {
  SPECIAL_HOLIDAY: blue[200],
  REGULAR_HOLIDAY: green[200],
  LEAVE: purple[200],
  ERROR: red[200],
  BLUE: blue[700],
  HR: purple[200],
  SYS: orange[300],
  ABSENT: red[200],
  OB: orange[200],
  REST_DAY: lime[200],
  EXEMPT: teal[200]
};

export const INVENTORY_TYPES = {
  new: {
    // fifo
    PURCHASES: 100,
    PURCHASE_RETURN: 190,

    SALES: 200,
    PHANTOM_SALES: 250,
    SALES_RETURN: 290,

    STOCK_ADJUSTMENT: 300,
    STOCK_ADJUSTMENT_IN: 320,
    STOCK_ADJUSTMENT_OUT: 330,
    STOCK_ADJUSTMENT_BY_VARIANCE: 340,
    STOCK_ADJUSTMENT_REPRICE: 350,

    STOCK_TRANSFER_BY_WH: 400,
    STOCK_TRANSFER_BY_BRANCH: 410,
    WORK_IN_PROCESS_TRANSFER: 420,

    PURCHASE_ORDER: 501,
    SALES_ORDER: 502,

    // weighted average
    WAVG_PURCHASES: 700,
    WAVG_SALES: 720,
    WAVG_STOCK_IN: 710,
    WAVG_STOCK_OUT: 711,

    DISABLED: 0,
    NONE: 0
  },
  old: {
    // fifo
    PURCHASES: 0,
    PURCHASE_RETURN: 5,

    SALES: 1,
    SALES_RETURN: 6,
    PHANTOM_SALES: 20,

    STOCK_ADJUSTMENT: 3,
    STOCK_ADJUSTMENT_IN: 12,
    STOCK_ADJUSTMENT_OUT: 13,
    STOCK_ADJUSTMENT_BY_VARIANCE: 10,
    STOCK_ADJUSTMENT_REPRICE: 21,

    STOCK_TRANSFER_BY_WH: 8,
    STOCK_TRANSFER_BY_BRANCH: 11,
    WORK_IN_PROCESS_TRANSFER: 19,

    PURCHASE_ORDER: 14,
    SALES_ORDER: 15,

    // weighted average
    WAVG_PURCHASES: 700,
    WAVG_SALES: 720,
    WAVG_STOCK_IN: 710,
    WAVG_STOCK_OUT: 711,

    DISABLED: 999,
    NONE: 0
  }
}['new'];

export const INV_QTY_TYPE = {
  [INVENTORY_TYPES.PURCHASES]: 'IN',
  [INVENTORY_TYPES.PURCHASE_RETURN]: 'OUT',

  [INVENTORY_TYPES.SALES]: 'OUT',
  [INVENTORY_TYPES.PHANTOM_SALES]: 'OUT',
  [INVENTORY_TYPES.SALES_RETURN]: 'IN',

  [INVENTORY_TYPES.STOCK_ADJUSTMENT]: 'ANY',
  [INVENTORY_TYPES.STOCK_ADJUSTMENT_IN]: 'IN',
  [INVENTORY_TYPES.STOCK_ADJUSTMENT_OUT]: 'OUT',
  [INVENTORY_TYPES.STOCK_ADJUSTMENT_BY_VARIANCE]: 'ANY',
  [INVENTORY_TYPES.STOCK_ADJUSTMENT_REPRICE]: 'OUT',

  [INVENTORY_TYPES.STOCK_TRANSFER_BY_WH]: 'OUT',
  [INVENTORY_TYPES.STOCK_TRANSFER_BY_BRANCH]: 'OUT',
  [INVENTORY_TYPES.WORK_IN_PROCESS_TRANSFER]: 'OUT',

  [INVENTORY_TYPES.PURCHASE_ORDER]: 'IN',
  [INVENTORY_TYPES.SALES_ORDER]: 'OUT',

  [INVENTORY_TYPES.WAVG_PURCHASES]: 'IN',
  [INVENTORY_TYPES.WAVG_SALES]: 'OUT',
  [INVENTORY_TYPES.WAVG_STOCK_IN]: 'IN',
  [INVENTORY_TYPES.WAVG_STOCK_OUT]: 'OUT',

  [INVENTORY_TYPES.DISABLED]: 'NONE',
  [INVENTORY_TYPES.NONE]: 'NONE'
};

export const STOCK_ADJUSTMENT_300_IN = '300-IN';
export const STOCK_ADJUSTMENT_300_OUT = '300-OUT';

export const IBFRS_COLORS = {
  DARK_GREEN: '#0399AF',
  LIGHT_GREEN: '#7DD858',
  BLUE: '#086FD8',
  GREEN_BLUE: '#1BE1DE',
  DARK_BLUE: '#002979',
  BLUE_DISABLED: '#005979',
  WHITE: '#FFFFFF'
};

export const PR_MANDATORY_DEDUCTIONS = ['sss', 'pagibig', 'philhealth', 'wht'];

export const DISCOUNT_SELECT_TYPE = {
  ALL_ITEMS: 0,
  SELECTED_ITEMS: 1
};

export const CASH_COUNT = [
  {
    id: 1,
    denomination: 1000,
    qty: 0,
    total: 0
  },
  {
    id: 2,
    denomination: 500,
    qty: 0,
    total: 0
  },
  {
    id: 3,
    denomination: 200,
    qty: 0,
    total: 0
  },
  {
    id: 4,
    denomination: 100,
    qty: 0,
    total: 0
  },
  {
    id: 5,
    denomination: 50,
    qty: 0,
    total: 0
  },
  {
    id: 6,
    denomination: 20,
    qty: 0,
    total: 0
  },
  {
    id: 7,
    denomination: 10,
    qty: 0,
    total: 0
  },
  {
    id: 8,
    denomination: 5,
    qty: 0,
    total: 0
  },
  {
    id: 9,
    denomination: 1,
    qty: 0,
    total: 0
  }
];

export const PR_VARIABLES_DEFAULT = {
  vLate: 0,
  vAbsent: 0,
  vUT: 0,
  vRH: 0,
  vSH: 0,
  vOT: 0,
  vOT_RD_OT: 0,
  vOT_RD: 0,
  vOT_RD_RH: 0,
  vOT_RD_SH: 0,
  vOT_RH: 0,
  vOT_SH: 0,
  valueOT: 0,
  vND: 0,
  vOT_ND: 0,
  vND_RH: 0,
  vND_SH: 0,
  vND_RD: 0,
  vND_RD_RH: 0,
  vND_RD_SH: 0,
  vPresent: 0,
  vHrs: 0,
  var1: 0,
  var2: 0,
  var3: 0,
  var4: 0,
  var5: 0,
  var6: 0,
  var7: 0,
  var8: 0,
  var9: 0
};

export const PR_VARIABLES_W_AUTO_UPDATE = [
  'vPresent',
  'vAbsent',
  'vLate',
  'vUT',

  'vOT',
  'vOT_RH',
  'vOT_SH',
  'vOT_ND',
  'vOT_ND_RH',
  'vOT_ND_SH',
  'vOT_RD',
  'vOT_RD_OT',
  'vOT_RD_RH',
  'vOT_RD_SH',
  'valueOT',

  'vND',
  'vND_RH',
  'vND_SH',
  'vND_RD',
  'vND_RD_RH',
  'vND_RD_SH',
  'vSH',
  'vRH',

  'var1',
  'var2',
  'var3',
  'var4',
  'var5',
  'var6',
  'var7',
  'var8',
  'var9'
];

export const PR_VARIABLES_CONFIG = {
  vPresent: {
    label: 'Days Present',
    unit: 'days'
  },
  vAbsent: {
    label: 'Days Absent',
    unit: 'days'
  },
  vLate: {
    label: 'Mins Late',
    unit: 'minutes'
  },
  vUT: {
    label: 'Mins Undertime',
    unit: 'minutes'
  },
  vOT: {
    label: 'Overtime',
    unit: 'hours'
  },
  vOT_RH: {
    label: 'OT on Reg Hol',
    unit: 'hours'
  },
  vOT_SH: {
    label: 'OT on Spe Hol',
    unit: 'hours'
  },
  vOT_ND: {
    label: 'ND on OT',
    unit: 'hours'
  },
  vOT_ND_RH: {
    label: 'ND on OT and RH',
    unit: 'hours'
  },
  vOT_ND_SH: {
    label: 'ND on OT and SH',
    unit: 'hours'
  },
  vOT_RD: {
    label: 'Work on Rest Day',
    unit: 'hours'
  },
  vOT_RD_OT: {
    label: 'OT on Rest Day',
    unit: 'hours'
  },
  vOT_RD_RH: {
    label: 'OT on Rest Day and RH',
    unit: 'hours'
  },
  vOT_RD_SH: {
    label: 'OT on Rest Day and SH',
    unit: 'hours'
  },
  valueOT: {
    label: 'OT Value',
    unit: 'none'
  },
  vND: {
    label: 'Night Diff.',
    unit: 'hours'
  },
  vND_RH: {
    label: 'Night Diff. on RH',
    unit: 'hours'
  },
  vND_SH: {
    label: 'Night Diff. on SH',
    unit: 'hours'
  },
  vND_RD: {
    label: 'Night Diff. on Rest Day',
    unit: 'hours'
  },
  vND_RD_RH: {
    label: 'Night Diff. on RD & RH',
    unit: 'hours'
  },
  vND_RD_SH: {
    label: 'Night Diff. on RD & SH',
    unit: 'hours'
  },

  vSH: {
    label: 'Special Holidays',
    unit: 'days'
  },
  vRH: {
    label: 'Regular Holidays',
    unit: 'days'
  }
};

export const PR_PRINT_LAYOUT_CODES = ['earn', 'ded', 'ps', 'register'];

export const BIZ_PLANS = {
  BIZ_BASIC: 100,
  PR_BASIC: 110,
  PR_BASIC_PLUS: 111,
  BIZ_PRO: 200,
  PR_PRO: 210,
  BIZ_AGENCY: 290,
  BIZ_ENTERPRISE: 300,
  PR_ENTERPRISE: 310
};

export const ACCOUNT_TYPES = {
  ASSETS: 1,
  LIABILITIES: 2,
  EQUITY: 3,
  REVENUES: 4,
  EXPENSES: 5,
  OTHER_INCOME: 6,
  OTHER_EXPENSES: 7
};

export const ACC_NORMAL_BALANCE = {
  [ACCOUNT_TYPES.ASSETS]: 'Dr',
  [ACCOUNT_TYPES.EXPENSES]: 'Dr',
  [ACCOUNT_TYPES.OTHER_EXPENSES]: 'Dr',
  [ACCOUNT_TYPES.LIABILITIES]: 'Cr',
  [ACCOUNT_TYPES.EQUITY]: 'Cr',
  [ACCOUNT_TYPES.REVENUES]: 'Cr',
  [ACCOUNT_TYPES.OTHER_INCOME]: 'Cr'
};

export const ACCOUNT_STYPE = {
  INVENTORY: 101,
  CHECKS_ON_HAND: 105,
  PPE: 109,
  SERVICE_INCOME: 407,
  CIB_CHECKING_ACC: 107,
  SALES_RETURN: 402,
  PURCHASE_RETURN: 404
};

export const TAX_SETTINGS = {
  TAX_DISABLED: 0,
  ON_DEBIT: 1,
  ON_CREDIT: 2
};

export const PR_TYPES = {
  MONTHLY: 0,
  SEMI_MONTHLY: 1,
  WEEKLY: 2,
  PIECE_RATE: 3
};

export const CHECK_MODES = {
  JV: 0,
  JE: 1
};

export const BUDGET_TYPES = {
  APPROP: 1,
  ALLOT: 2,
  OBLIG: 3,
  DV: 4,
  APPROP_ADJ: 11,
  SUPP: 12,
  APPROP_BUD_ADJ: 13,
  APPROP_DEC: 14,
  ALLOT_ADJ: 21,
  OBLIG_ADJ: 31,
  OBLIG_ADJ_DEC: 34,
  SUPP_ADJ: 15
};

export const UNITS = {
  PCS: 0,
  SC: 1,
  CS: 2
};

export const IN_INV = [
  INVENTORY_TYPES.PURCHASES,
  INVENTORY_TYPES.PURCHASE_ORDER,
  INVENTORY_TYPES.PURCHASE_RETURN,
  INVENTORY_TYPES.STOCK_ADJUSTMENT_IN,
  INVENTORY_TYPES.WAVG_PURCHASES,
  INVENTORY_TYPES.WAVG_STOCK_IN
];

export const OUT_INV = [
  INVENTORY_TYPES.SALES,
  INVENTORY_TYPES.SALES_RETURN,
  INVENTORY_TYPES.SALES_ORDER,
  INVENTORY_TYPES.PHANTOM_SALES,
  INVENTORY_TYPES.STOCK_ADJUSTMENT_OUT,
  INVENTORY_TYPES.STOCK_TRANSFER_BY_BRANCH,
  INVENTORY_TYPES.STOCK_TRANSFER_BY_WH,
  INVENTORY_TYPES.STOCK_ADJUSTMENT_REPRICE,
  INVENTORY_TYPES.WAVG_SALES,
  INVENTORY_TYPES.WAVG_STOCK_OUT
];
export const INV_MAX_ROWS = 200;

export const PR_MODES = {
  STANDARD: 0,
  DAILY: 1,
  HOURLY: 2
};

export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 7;

export const DAY_NO_DESC = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
};

export const PRICE_TYPES = [
  {
    prop: 'cCost',
    value: 6,
    caption: 'Cost'
  },
  {
    prop: 'cPrice1',
    value: 1,
    caption: 'Price 1'
  },
  {
    prop: 'cPrice2',
    value: 2,
    caption: 'Price 2'
  },
  {
    prop: 'cPrice3',
    value: 3,
    caption: 'Price 3'
  },
  {
    prop: 'cPrice4',
    value: 4,
    caption: 'Price 4'
  },
  {
    prop: 'cPrice5',
    value: 5,
    caption: 'Price 5'
  }
];

export const FS_TYPES = {
  TRIAL_BAL: 100,
  INCOME_STATEMENT: 200,
  COMPARATIVE_INCOME_STATEMENT: 201,
  INCOME_STATEMENT_BY_BRCH: 202,
  BALANCE_SHEET: 300,
  COMPARATIVE_BALANCE_SHEET: 301,
  COMPARATIVE_BALANCE_SHEET_BY_BRCH: 302,
  CASH_FLOW: 400,
  CASH_FLOW_BY_BRCH: 402,
  CHANGES_IN_EQUITY: 500
};

export const TRANS_BRANCH_MODES = {
  DISABLED: 0,
  REQUIRED: 1,
  MULTI_BRANCH_JE_ONLY: 2,
  MULTI_BRANCH_JE_AND_JV: 3
};

export const JNT_ERRORS = {
  S01: {
    desc: 'MD5、Base64',
    explanation: 'Illegal JSON format'
  },
  S02: {
    desc: 'Illegal digital signature',
    explanation:
      'The data_digest signature is incorrect and fails the verification'
  },
  S03: {
    desc: 'Illegal client company number',
    explanation: 'eccompanyid is incorrect and failed the verification'
  },
  S04: {
    desc: 'Illegal notification type',
    explanation:
      'The value content of the service type msg_type is not in the range specified in the document'
  },
  S05: {
    desc: 'Illegal notification content',
    explanation: 'The format of the content in logistics_interface is incorrect'
  },
  S06: {
    desc: 'Connection timed out',
    explanation:
      'When creating an order, if no response is received within 30 seconds, it is considered a timeout'
  },
  S07: {
    desc: 'System failure, please try again',
    explanation: 'Database exception or other system errors'
  },
  S08: {
    desc: 'Illegal e-commerce platform',
    explanation: 'eccompanyid error'
  },
  S09: {
    desc: 'No task data returned',
    explanation:
      'Freight query, express tracking query, return when there is no record'
  },
  S10: {
    desc: 'Duplicate order number',
    explanation: 'Online order interface txlogisticid Duplicate order number'
  },
  S11: {
    desc: 'Duplicate waybill number',
    explanation: 'Online order interface mailno duplicate waybill number'
  },
  S12: {
    desc: 'The order status is GOT and the order cannot be cancelled',
    explanation:
      'Order update interface, when the order has been received, the order cannot be cancelled'
  },
  S13: {
    desc: 'Customer identification error',
    explanation:
      'The online order interface customer ID customerid value is wrong, the system does not exist'
  },
  S14: {
    desc: 'Waybill number segment error',
    explanation:
      'The waybill number segment has been used up or the waybill number segment has not been issued'
  },
  S15: {
    desc: 'Order number does not exist',
    explanation:
      'Online order interface txlogisticid. The order number does not exist and cannot be modified'
  },
  S16: {
    desc: 'Waybill number does not exist',
    explanation:
      'Online ordering interface mailno. The waybill number does not exist and cannot be modified'
  },
  S17: {
    desc: 'No task data returned',
    explanation:
      'The shipping outlet of the waybill has not been checked / frequent operation'
  },
  S18: {
    desc: 'The query command is empty',
    explanation: 'The query command cannot be empty'
  },
  S19: {
    desc: 'Start time cannot be empty',
    explanation: 'Start time cannot be empty'
  },
  S20: {
    desc: 'End time cannot be empty',
    explanation: 'End time cannot be empty'
  },
  S21: {
    desc: 'Pick-up start time format has wrong',
    explanation: 'Pick-up start time format has wrong'
  },
  S22: {
    desc: 'Pick-up end time format has wrong',
    explanation: 'Pick-up end time format has wrong'
  },
  B001: {
    desc: 'eccompanyid cannot be empty',
    explanation:
      'Online order interface message provider ID eccompanyid cannot be empty'
  },
  B002: {
    desc: 'customerid cannot be empty',
    explanation: 'Online order interface customer ID customerid cannot be empty'
  },
  B003: {
    desc: 'txlogisticid cannot be empty',
    explanation:
      'Online order interface logistics order number txlogisticid cannot be empty'
  },
  B004: {
    desc: 'ordertype cannot be empty',
    explanation: 'Online order interface order type ordertype cannot be empty'
  },
  B005: {
    desc: 'servicetype cannot be empty',
    explanation:
      'Online order interface service type servicetype cannot be empty'
  },
  B006: {
    desc: 'Sender name sender name cannot be empty',
    explanation: 'Sender name cannot be empty'
  },
  B007: {
    desc: 'Sender mailbox cannot be empty',
    explanation: 'Online order interface sender mailbox cannot be empty'
  },
  B008: {
    desc: 'Sender mobile cannot be empty',
    explanation:
      'The online order interface sends the mobile phone sender mobile cannot be empty'
  },
  B009: {
    desc: 'Sender province sender prov cannot be empty',
    explanation:
      'Online order interface sending province sender prov cannot be empty'
  },
  B010: {
    desc: 'Sender city cannot be empty',
    explanation:
      'Online order interface sender city sender city cannot be empty'
  },
  B011: {
    desc: 'Sender area cannot be empty',
    explanation: 'Online order interface sender area cannot be empty'
  },
  B012: {
    desc: 'Sender address cannot be empty',
    explanation:
      'Sending address of the online order interface sender address cannot be empty'
  },
  B013: {
    desc: 'Receiver name receiver name cannot be empty',
    explanation:
      'Receiver name in the online order interface receiver name cannot be empty'
  },
  B014: {
    desc: 'Receiver mobile cannot be empty',
    explanation:
      'Online order interface receiving mobile phone receiver mobile cannot be empty'
  },
  B015: {
    desc: 'The recipient province receiver prov cannot be empty',
    explanation:
      'Online order interface receiving province receiver prov cannot be empty'
  },
  B016: {
    desc: 'Receiving city receiver city cannot be empty',
    explanation:
      'Online order interface receiving city receiver city cannot be empty'
  },
  B017: {
    desc: 'The receiving area receiver area cannot be empty',
    explanation:
      'The receiving area of ​​the online order interface receiver area cannot be empty'
  },
  B018: {
    desc: 'The recipient address receiver address cannot be empty',
    explanation:
      'The receiving address of the online order interface receiver address cannot be empty'
  },
  B019: {
    desc: 'Order creation time createordertime cannot be empty',
    explanation:
      'Online order interface order creation time createordertime cannot be empty'
  },
  B020: {
    desc: 'Sendstarttime cannot be empty',
    explanation:
      "Online order interface logistics company's pick-up start time sendstarttime cannot be empty"
  },
  B021: {
    desc: 'Sendendtime cannot be empty',
    explanation:
      'Online order interface logistics company pick-up end time sendendtime cannot be empty'
  },
  B022: {
    desc: 'The payment method paytype cannot be empty',
    explanation: 'Online order interface payment method paytype cannot be empty'
  },
  B023: {
    desc: 'Total quantity cannot be empty',
    explanation:
      'The total quantity of the online order interface cannot be empty'
  },
  B024: {
    desc: 'Itemsvalue cannot be empty',
    explanation:
      'The online order interface collects the payment amount itemsvalue cannot be empty'
  },
  B025: {
    desc: 'Chinese item name items itemname cannot be empty',
    explanation:
      'Online order interface Chinese item name items itemname cannot be empty'
  },
  B026: {
    desc: 'English item name items englishName cannot be empty',
    explanation:
      'Online order interface English product name items englishName cannot be empty'
  },
  B027: {
    desc: 'Items number cannot be empty',
    explanation:
      'The number of online order interface items number cannot be empty'
  },
  B028: {
    desc: 'Declared value (IDR) items itemvalue cannot be empty',
    explanation:
      'Online order interface declared value (IDR) items number cannot be empty'
  },
  B029: {
    desc: 'Item URL items itemurl cannot be empty',
    explanation: 'Item URL items itemurl cannot be empty'
  },
  B030: {
    desc: 'Sender city does not exist',
    explanation:
      'The online order interface is judged when the order type is a transfer order or a return order'
  },
  B031: {
    desc: 'Sender area does not exist',
    explanation:
      'The online order interface is judged when the order type is a transfer order or a return order'
  },
  B032: {
    desc: 'The receiving area receiverarea does not exist',
    explanation:
      'Online order interface receiving area receiver>area does not exist'
  },
  B033: {
    desc: 'Receiver postcode error',
    explanation:
      'The online order interface cannot find the receiving area based on the receiving zip code'
  },
  B034: {
    desc: 'Receiver city error',
    explanation:
      'The receiving city of the online order interface does not exist'
  },
  B035: {
    desc: 'items wrong',
    explanation:
      'The item details of the online order interface cannot exceed 10'
  },
  B036: {
    desc: 'Receiver postcode error',
    explanation:
      'The online order interface receiving postal code is not in harmony with the rules, and it can only be 5 pure numbers'
  },
  B037: {
    desc: 'Start date wrong',
    explanation:
      'The start time of the overseas warehouse inventory query interface and cargo status query interface cannot be empty'
  },
  B038: {
    desc: 'End time enddate error',
    explanation:
      'The end time of the overseas warehouse inventory query interface and cargo status query interface cannot be empty'
  },
  B039: {
    desc: 'Country is wrong',
    explanation:
      'The country of the overseas warehouse inventory query interface and the cargo status query interface cannot be empty'
  },
  B040: {
    desc: 'startdate, enddate error',
    explanation:
      'The time period of the overseas warehouse inventory query interface and cargo status query interface cannot exceed one month (31 days)'
  },
  B041: {
    desc: 'Query command error',
    explanation:
      'The query command of the overseas warehouse inventory query interface and cargo status query interface cannot be empty'
  },
  B042: {
    desc: 'Serialnumber error',
    explanation:
      'The number of the overseas warehouse inventory query interface and cargo status query interface (multiple numbers are separated by commas) cannot be empty'
  },
  B043: {
    desc: 'Query command error',
    explanation:
      'Overseas warehouse inventory query interface, cargo status query interface query command is not in the scope of the document'
  },
  B044: {
    desc: 'Stock status, inventory status error',
    explanation:
      'Overseas warehouse inventory query interface, cargo status query interface, cargo status, and inventory status are not within the scope of the document'
  },
  B045: {
    desc: 'Country is wrong',
    explanation:
      'The country to which the overseas warehouse inventory query interface and the cargo status query interface belong is not within the scope of the document'
  },
  B046: {
    desc: 'The list of sub-order numbers cannot be empty',
    explanation:
      'Online order interface sub-order number List collection cannot be empty'
  },
  B047: {
    desc: 'Order type ordertype error',
    explanation:
      'Online order interface The order type is not within the scope of the document'
  },
  B048: {
    desc: 'Servicetype error',
    explanation:
      'The online order interface service type is not within the scope of the document'
  },
  B049: {
    desc: 'Receive country code countrycode error',
    explanation:
      'The country code of the online order interface is not in the scope of the document'
  },
  B050: {
    desc: 'Receive country code countrycode error',
    explanation:
      'The receiving country code of the online order interface is not within the scope of the document'
  },
  B051: {
    desc: 'Wrong pricecurrency currency',
    explanation:
      'The currency of the online order interface is not within the scope of the document'
  },
  B052: {
    desc: 'Wrong pricecurrency currency',
    explanation:
      'Online order interface to collect the payment currency pricecurrency matches the recipient country'
  },
  B053: {
    desc: 'Countrycode cannot be empty',
    explanation: 'Countrycode cannot be empty'
  },
  B054: {
    desc: 'Receiving country code countrycode cannot be empty',
    explanation:
      'The country code of the receiving country code of the online order interface cannot be empty'
  },
  B055: {
    desc: 'Order does not exist',
    explanation: 'Cancel order interface the order does not exist'
  },
  B056: {
    desc: 'The order has been received and cannot be cancelled',
    explanation:
      'Cancel order interface the order has been received and cannot be cancelled'
  },
  B057: {
    desc: 'Cancel order exception',
    explanation: 'Cancel order interface cancel order exception'
  },
  B058: {
    desc: 'The sending province and city address do not match',
    explanation:
      'The province and city address of the online ordering interface do not match'
  },
  B059: {
    desc: 'The recipient province and city address do not match',
    explanation:
      'The receiving province and city address of the online order interface do not match'
  },
  B060: {
    desc: 'actiontype cannot be empty',
    explanation:
      'Online order interface actiontype determines whether to add or modify the operation (add/update)'
  },
  B061: {
    desc:
      'The status of the waybill is not to be shipped and cannot be modified',
    explanation:
      'The status of the waybill is not to be shipped and cannot be modified (PENDING)'
  },
  B062: {
    desc: "The sender's city address does not match",
    explanation:
      'The online order interface does not match the province and city address'
  },
  B063: {
    desc:
      "The address of the recipient's province, city and district does not match",
    explanation:
      'The address of the receiving province, city, or city in the online order interface does not match'
  },
  B064: {
    desc: 'Weight can not be less than 0.01KG, can not exceed 50KG',
    explanation:
      'The weight of the online order interface can not be less than 0.01KG, can not exceed 50KG'
  },
  B065: {
    desc: 'The volume cannot be greater than 50KG',
    explanation:
      'The volume of the online order interface cannot be greater than 50KG'
  },
  B066: {
    desc: 'SHOPEE seller ID cannot be empty',
    explanation: 'Online order interface SHOPEE seller ID cannot be empty'
  },
  B067: {
    desc: 'SHOPEE seller email cannot be empty',
    explanation: 'Online order interface SHOPEE seller mailbox cannot be empty'
  },
  B068: {
    desc: 'Itemvalue can not be 0',
    explanation: 'Online order interface Itemvalue can not be 0'
  },
  B069: {
    desc: 'Item list details can not be null',
    explanation: 'Online order interface Item list can not be null'
  },
  B070: {
    desc: 'Total goods value maxium is 50K',
    explanation: 'Online order interface Total goods value maxium is 50K'
  },
  B071: {
    desc: 'COD amount maxium is 50K',
    explanation: 'Online order interface COD amount maxium is 50K'
  }
};

export const QTY_INPUT_UI_MODE = {
  BASIC: 0,
  WITH_UNIT: 1,
  CASE: 2
};

export const TIME_LOGS_VARIABLES = [
  'vOT',
  'vOT_RH',
  'vOT_SH',
  'vOT_ND',
  'vOT_ND_RH',
  'vOT_ND_SH',
  'vOT_RD',
  'vOT_RD_OT',
  'vOT_RD_RH',
  'vOT_RD_SH',
  'vOT_BRK',
  'valueOT',
  'vND',
  'vND_RH',
  'vND_SH',
  'vND_RD',
  'vND_RD_RH',
  'vND_RD_SH',
  'vPresent',
  'vHrs',
  'vAbsent',
  'vLate',
  'vUT',
  'vOB',
  'vRH',
  'vSH',
  'vSL',
  'vVL',
  'vML',
  'vPL',
  'var1',
  'var2',
  'var3',
  'var4',
  'var5',
  'var6',
  'var7',
  'var8',
  'var9'
];
