import { createSlice } from '@reduxjs/toolkit';
import APIRequest, { cancelAPIRequest } from 'src/helpers/APIRequest';
import { qtyPCToCS } from 'src/helpers';
import { v4 as uuid } from 'uuid';

const INITIAL_STATE = {
  ixJCd: '',
  ixWH: '',
  subWarehouse: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  },
  filterBy: 'all',
  ixBrch: 0,
  showAll: false,
  requireBrch: false,
  ixOrderStatus: 1,
  showProdDetails: true,
  showAddress: true,
  showProdCategory: true,
  searchValue: '',
  ixProdCat: 0,
  ixProdCatSub: 0,
  isFetchingMeta: false,
  isFetchingData: false,
  showCS: false,
  showSC: false,
  data: [],
  dates: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  page: 1,
  itemPerPage: 10,
  meta: {
    jcd: [],
    order_status: {
      0: 'Any',
      1: 'Open',
      2: 'Closed',
      3: 'Force Closed'
    }
  },
  type: '',
  storedData: {},
  ixProdSubLink1: 0,
  sProdSubLink1: '',
  ixProdSubLink2: 0,
  sProdSubLink2: '',
  ixProdSubLink3: 0,
  sProdSubLink3: '',
  ixProdSubLink4: 0,
  sProdSubLink4: '',
  ixProdSubLink5: 0,
  sProdSubLink5: '',
  showSubAcc2: false,
  showSubAcc3: false,
  showSubAcc4: false,
  showSubAcc5: false
};

const slice = createSlice({
  name: 'orderSummary',
  initialState: INITIAL_STATE,
  reducers: {
    onGetOrderSummaryMeta(state) {
      state.isFetchingMeta = true;
    },
    loadOrderSummaryMeta(state, action) {
      state.isFetchingMeta = false;
      state.meta = action.payload;
    },
    getOrderSummaryFailed(state) {
      state.isFetchingMeta = false;
    },
    selectTransactionType(state, action) {
      state.ixJCd = action.payload;
    },
    changeOrderSummaryDates(state, action) {
      state.dates = action.payload;
    },
    changeOrderSummaryWH(state, action) {
      state.ixWH = action.payload;
    },
    changeOrderSummaryProdCat(state, action) {
      state.ixProdCat = action.payload;
    },
    changeOrderSummaryProdCatSub(state, action) {
      state.ixProdCatSub = action.payload;
    },
    changeOrderSummaryOrdStatus(state, action) {
      state.ixOrderStatus = action.payload;
    },
    toggleShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    toggleShowProdCategory(state, action) {
      state.showProdCategory = action.payload;
    },
    toggleShowAddress(state, action) {
      state.showAddress = action.payload;
    },
    onFetchOrderSummaryData(state) {
      state.isFetchingData = true;
    },
    getOrderSummaryDataFailed(state) {
      state.isFetchingData = false;
    },
    loadOrderSummaryData(state, action) {
      state.data = action.payload.map(item => {
        let additionalProps = {};
        [
          'qtyORD',
          'ordPrice',
          'qtyForDEL',
          'qtyDEL',
          'qtyORDBAL',
          'qtyUNDELORD'
        ].forEach(prop => {
          additionalProps = {
            ...additionalProps,
            ...qtyPCToCS({
              libQtyCS: item?.qtyCS ?? 0,
              libQtySC: item?.qtySC ?? 0,
              qty: item?.[prop] ?? 0,
              keyProp: prop
            })
          };
        });

        return {
          id: uuid(),
          ...item,
          ...additionalProps
        };
      });
      state.showCS = action.payload.some(item => item.qtyCS > 0);
      state.showSC = action.payload.some(item => item.qtySC > 0);
      state.showAll = false;

      for (let i = 2; i <= 5; i++)
        state[`showSubAcc${i}`] = action.payload.some(
          item => item[`ixSubAcc${i}`] > 0
        );

      state.isFetchingData = false;
    },
    loadAllOrderSummaryData(state, action) {
      state.data = action.payload.map(item => {
        let additionalProps = {};
        [
          'qtyORD',
          'ordPrice',
          'qtyForDEL',
          'qtyDEL',
          'qtyORDBAL',
          'qtyUNDELORD'
        ].forEach(prop => {
          additionalProps = {
            ...additionalProps,
            ...qtyPCToCS({
              libQtyCS: item?.qtyCS ?? 0,
              libQtySC: item?.qtySC ?? 0,
              qty: item?.[prop] ?? 0,
              keyProp: prop
            })
          };
        });

        return {
          id: uuid(),
          ...item,
          ...additionalProps
        };
      });
      state.showCS = action.payload.some(item => item.qtyCS > 0);
      state.showSC = action.payload.some(item => item.qtySC > 0);
      state.showAll = true;

      for (let i = 2; i <= 5; i++)
        state[`showSubAcc${i}`] = action.payload.some(
          item => item[`ixSubAcc${i}`] > 0
        );

      state.isFetchingData = false;
    },
    changeOrderSummaryPage(state, action) {
      state.page = action.payload;
    },
    changeOrderSummaryItemPerPage(state, action) {
      state.itemPerPage = action.payload;
      state.page = 1;
    },
    changeOrderSummaryBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setOrderSummaryRequireBranch(state, action) {
      state.requireBrch = action.payload;
    },
    clearOrderSummary(state) {
      state.data = [];
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    storeData(state, action) {
      return action.payload;
    },
    setProdSubLink1(state, action) {
      state.ixProdSubLink1 = action.payload.ixProdSubLink1;
      state.sProdSubLink1 = action.payload.sProdSubLink1;
    },
    setProdSubLink2(state, action) {
      state.ixProdSubLink2 = action.payload.ixProdSubLink2;
      state.sProdSubLink2 = action.payload.sProdSubLink2;
    },
    setProdSubLink3(state, action) {
      state.ixProdSubLink3 = action.payload.ixProdSubLink3;
      state.sProdSubLink3 = action.payload.sProdSubLink3;
    },
    setProdSubLink4(state, action) {
      state.ixProdSubLink4 = action.payload.ixProdSubLink4;
      state.sProdSubLink4 = action.payload.sProdSubLink4;
    },
    setProdSubLink5(state, action) {
      state.ixProdSubLink5 = action.payload.ixProdSubLink5;
      state.sProdSubLink5 = action.payload.sProdSubLink5;
    },
    resetOrderSummary() {
      return INITIAL_STATE;
    },
    setSubWarehouse(state, action) {
      state.subWarehouse = action.payload;
    },
    setFilterBy(state, action) {
      state.filterBy = action.payload;
      state.searchValue = '';
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const cancelOrderSummaryRequest = () => (dispatch, _) => {
  cancelAPIRequest();
  dispatch(slice.actions.getOrderSummaryDataFailed());
};

export const storeData = type => (dispatch, getState) => {
  const { storedData, meta, ...rest } = getState().orderSummary;

  if (type !== rest.type) {
    let copyStoredData = {};

    if (rest.type !== '') {
      copyStoredData = {
        ...copyStoredData,
        [rest.type]: rest
      };
    }

    const newState = storedData?.[type] ?? INITIAL_STATE;

    dispatch(
      slice.actions.storeData({
        ...newState,
        meta,
        type,
        storedData: copyStoredData
      })
    );
  }
};

export const getOrderSummaryMeta = notify => async (dispatch, getState) => {
  const { userToken, base_url } = getState().auth;
  dispatch(slice.actions.onGetOrderSummaryMeta());

  const { data, success, error, isCancelled } = await APIRequest({
    method: 'GET',
    url: `${base_url}/reports/order-summary/meta`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (success) {
    dispatch(slice.actions.loadOrderSummaryMeta(data));
    return;
  }

  if (isCancelled) return;

  dispatch(slice.actions.getOrderSummaryDataFailed());
  console.error('error details : ', error);

  notify.error('Failed to fetch order summary settings');
};

export const getOrderSummaryData = (notify, showDetails, pg = 0) => async (
  dispatch,
  getState
) => {
  const { userToken, base_url } = getState().auth;
  const {
    ixJCd,
    ixWH,
    ixBrch,
    ixOrderStatus,
    showProdDetails,
    ixProdCat,
    ixProdCatSub,
    dates,
    page,
    itemPerPage,
    ixProdSubLink1,
    ixProdSubLink2,
    ixProdSubLink3,
    ixProdSubLink4,
    ixProdSubLink5,
    subWarehouse
  } = getState().orderSummary;

  dispatch(slice.actions.onFetchOrderSummaryData());

  const payload = {
    ixJCd,
    ixWH,
    ixBrch,
    ixOrderStatus,
    showProdDetails:
      showDetails === showProdDetails ? showProdDetails : showDetails,
    ixProdCat,
    ixProdCatSub,
    page: pg !== 0 ? pg : page,
    limit: itemPerPage,
    ixProdSubLink1,
    ixProdSubLink2,
    ixProdSubLink3,
    ixProdSubLink4,
    ixProdSubLink5,
    ...dates
  };

  if (subWarehouse?.show) payload.ixWHSub = subWarehouse?.ixSubAcc;

  const { success, data, error, isCancelled } = await APIRequest({
    method: 'POST',
    url: `${base_url}/reports/order-summary`,
    data: payload,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (success) {
    dispatch(slice.actions.toggleShowProdDetails(showDetails));

    if (pg !== 0) {
      dispatch(slice.actions.changeOrderSummaryPage(payload.page));
      dispatch(slice.actions.loadOrderSummaryData(data));
    } else dispatch(slice.actions.getOrderSummaryDataFailed());

    return;
  }

  if (isCancelled) return;

  dispatch(slice.actions.getOrderSummaryDataFailed());
  console.error(error);
  notify.error(
    typeof error === 'object' ? 'Failed to fetch order summary data' : error
  );
};

export const changeOrderSummaryPage = (payload, notify) => async (
  dispatch,
  getState
) => {
  const { showProdDetails } = getState().orderSummary;
  dispatch(getOrderSummaryData(notify, showProdDetails, payload));
};

export const changeOrderSummaryItemPerPage = (payload, notify) => async (
  dispatch,
  getState
) => {
  const { showProdDetails } = getState().orderSummary;
  dispatch(slice.actions.changeOrderSummaryItemPerPage(payload));
  dispatch(getOrderSummaryData(notify, showProdDetails, 1));
};

const orderSummaryReducer = slice.reducer;

export const {
  onGetOrderSummaryMeta,
  loadOrderSummaryMeta,
  getOrderSummaryFailed,
  selectTransactionType,
  changeOrderSummaryDates,
  changeOrderSummaryWH,
  changeOrderSummaryProdCat,
  changeOrderSummaryProdCatSub,
  changeOrderSummaryOrdStatus,
  toggleShowProdDetails,
  toggleShowAddress,
  toggleShowProdCategory,
  setSearchValue,
  onFetchOrderSummaryData,
  getOrderSummaryDataFailed,
  loadOrderSummaryData,
  loadAllOrderSummaryData,
  changeOrderSummaryBranch,
  setOrderSummaryRequireBranch,
  clearOrderSummary,
  resetOrderSummary,
  setProdSubLink1,
  setProdSubLink2,
  setProdSubLink3,
  setProdSubLink4,
  setProdSubLink5,
  setSubWarehouse,
  setFilterBy,
  restore,
  clear
} = slice.actions;
export default orderSummaryReducer;
