import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, TableCell, TextField, MenuItem } from '@material-ui/core';
import { convertToNumber, getCellValue } from '../utils';
import clsx from 'clsx';
import { isString } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

const inputRegex = /^[0-9a-zA-Z~`!@#$%^&*()_\-+={}[\]|\\:;"'<>,.?/]$/;

const useStyles = makeStyles({
  textField: {
    height: '100%',
    '& .MuiOutlinedInput-root': {
      height: '100%'
    },
    '& .MuiInputBase-input': {
      fontSize: '0.875rem',
      paddingLeft: 10,
      paddingRight: 10
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderRadius: 0
    },
    '& input': {
      padding: 4
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20
  }
});

/**
 *
 * @param {object} param
 * @param {string} param.className,
 * @param {column} param.col,
 * @param {object} param.item,
 * @param {object} param.style,
 * @param {number|string} param.rowIdKey,
 * @param {(params:cellParams,event:React.MouseEventHandler<HTMLTableCellElement>)=>void} param.onCellClick
 * @param {(params:cellParams,event:React.MouseEventHandler<HTMLTableCellElement>)} param.onCellDoubleClick
 * @param {(params:cellParams&{col:column},event:React.FocusEvent<HTMLInputElement>)} param.onCellEdit
 * @param {(rowIndex:number,colIndex:number)} param.focusCell
 * @param {number} param.rowIndex
 * @param {number} param.colIndex
 * @param {React.RefObject<HTMLTableCellElement>} param.cellRef
 * @returns
 */
const Cell = ({
  onCellDoubleClick,
  onCellClick,
  onCellEdit,
  focusCell,
  className,
  col,
  item,
  style,
  rowIDKey,
  rowIndex,
  colIndex,
  cellRef
}) => {
  let clickTimeout;

  const { textField, padding } = useStyles();

  const txtBox = useRef(null);
  const escapePressed = useRef(false);

  const [edit, setEdit] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [tempVal, setTempVal] = useState(item[col.field]);

  /**
   * @description
   * Handles double click event on cell.
   * If cell is editable, edit mode is enabled.
   * If cell is not editable, onCellDoubleClick is called.
   * @param {React.MouseEvent<HTMLTableCellElement>} event
   */
  function onDoubleClick(event) {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = null;
    }

    if (col?.editable) setEdit(true);
    else
      onCellDoubleClick(
        {
          id: item[rowIDKey],
          field: col.field,
          value: item[col.field],
          row: item,
          editable: col?.editable || false,
          colDef: col
        },
        event
      );
  }

  function getCellClassName() {
    if (!col.hasOwnProperty('cellClassName')) return '';
    if (isString(col.cellClassName)) return col.cellClassName;

    const className = col.cellClassName({
      id: item[rowIDKey],
      field: col.field,
      value: item[col.field],
      row: item,
      editable: col?.editable || false,
      colDef: col
    });

    return className;
  }

  /**
   * @description
   * Handles click event on cell.
   * If click timeout is not set, it will be set.
   * After 250ms, onCellClick is called with row and column data.
   * @param {React.MouseEvent<HTMLTableCellElement>} event
   */
  function onClick(event) {
    if (!clickTimeout) {
      clickTimeout = setTimeout(() => {
        onCellClick(
          {
            id: item[rowIDKey],
            field: col.field,
            value: item[col.field],
            row: item,
            editable: col?.editable || false,
            colDef: col
          },
          event
        );
        clickTimeout = null;
      }, 250);
    }
  }

  /**
   * @description
   * Handles blur event on text box.
   * If escape key is pressed, edit mode is disabled and value is not changed.
   * Otherwise, onCellEdit is called with row and column data.
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
  function onTextBoxBlur(event) {
    setEdit(false);
    if (escapePressed.current) {
      escapePressed.current = false;
    } else {
      let value = event.target.value;
      if (col?.type === 'number') value = convertToNumber(value);

      onCellEdit(
        {
          id: item[rowIDKey],
          field: col.field,
          value,
          row: item,
          editable: col?.editable || false,
          col
        },
        event
      );
    }

    setTempVal(item[col.field]);
  }

  /**
   *
   * @param {React.FocusEvent<HTMLTableCellElement>} event
   */
  function handleCellKeydown(event) {
    if (!edit) {
      if (event.key === 'Enter' && col?.editable) {
        setEdit(true);
      } else if (
        (event.key === 'Backspace' || event.key === 'Delete') &&
        col?.editable
      ) {
        onCellEdit(
          {
            id: item[rowIDKey],
            field: col.field,
            value: col?.type === 'number' ? 0 : '',
            row: item,
            editable: col?.editable || false,
            col
          },
          event
        );
      } else if (inputRegex.test(event.key) && col?.editable) {
        event.preventDefault();
        setTempVal(event.key);
        setEdit(true);
      } else if (event.key === 'ArrowDown') {
        focusCell(rowIndex + 1, colIndex);
      } else if (event.key === 'ArrowUp') {
        focusCell(rowIndex - 1, colIndex);
      } else if (event.key === 'ArrowLeft') {
        focusCell(rowIndex, colIndex - 1);
      } else if (event.key === 'ArrowRight') {
        focusCell(rowIndex, colIndex + 1);
      }
    }
  }

  function handleTextBoxKeydown(event) {
    if (event.key === 'Enter') {
      event.target.blur();
      focusCell(rowIndex + 1, colIndex);
    }

    if (event.key === 'Escape') {
      escapePressed.current = true;
      event.target.blur();
    }
  }

  useEffect(() => {
    setTempVal(item[col.field]);
  }, [item, col]);

  return (
    <TableCell
      ref={cellRef}
      aria-hidden={false}
      tabIndex={0}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      align={col?.align || 'left'}
      className={clsx(getCellClassName(), className, !edit && padding)}
      onKeyDown={handleCellKeydown}
      style={style}
    >
      {edit ? (
        col?.type === 'select' ? (
          <Autocomplete
            fullWidth
            options={col?.options || []}
            getOptionLabel={option => option.label}
            value={col.options.find(opt => opt.value === tempVal) || null}
            onChange={(e, newValue) =>
              setTempVal(newValue ? newValue.value : '')
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                inputRef={txtBox}
                className={textField}
                autoFocus
                onBlur={onTextBoxBlur}
                onKeyDown={handleTextBoxKeydown}
              />
            )}
          />
        ) : (
          <TextField
            fullWidth
            select={col?.type === 'select'}
            type={col.hasOwnProperty('type') ? col.type : 'text'}
            inputRef={txtBox}
            value={tempVal || ''}
            onChange={e => setTempVal(e.target.value)}
            className={textField}
            variant="outlined"
            onBlur={onTextBoxBlur}
            onKeyDown={handleTextBoxKeydown}
            autoFocus
          >
            {col?.type === 'select' &&
              col?.options?.length &&
              col.options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )
      ) : (
        <div className={padding}>
          {getCellValue({
            col,
            row: item,
            id: item[rowIDKey],
            value: item[col.field],
            field: col.field,
            hasFocus
          })}
        </div>
      )}
    </TableCell>
  );
};

export default React.memo(Cell);
