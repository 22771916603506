import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  meta: {},
  data: {
    items: [],
    perExpTotal: [],
    grandTotal: {}
  },
  showOtherDetails: false,
  showPercentage: false,
  showVsApprop: false,
  showVsAllot: false,
  showVsOblig: false,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  ixBrch: 0
};

const slice = createSlice({
  name: 'budgetCO',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setShowOtherDetails(state, action) {
      state.showOtherDetails = action.payload;
    },
    setShowPercentage(state, action) {
      state.showPercentage = action.payload;
    },
    clearData(state, action) {
      state.data = { items: [], perExpTotal: [], grandTotal: {} };
    },
    setShowVsApprop(state, action) {
      state.showVsApprop = action.payload;
    },
    setShowVsAllot(state, action) {
      state.showVsAllot = action.payload;
    },
    setShowVsOblig(state, action) {
      state.showVsOblig = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const budgetCOReducer = slice.reducer;
export const {
  setMeta,
  setData,
  setDateRange,
  setBranch,
  clearData,
  setShowOtherDetails,
  setShowPercentage,
  setShowVsApprop,
  setShowVsAllot,
  setShowVsOblig,
  clear,
  restore
} = slice.actions;
export default budgetCOReducer;
